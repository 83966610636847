import React from "react";
import { Icon, IconNames } from "@metyis-ds/icon";
import {
  Text1Light,
  Text1Bold,
  Text4Bold,
  Text4Light
} from "@metyis-ds/typography";
import { Value } from "@metyis-ds/value";
import content from "./financial.content";
import { Chart } from "@components/chart/Chart";

export interface IProps {
  costs: number;
  iconName: IconNames;
  sales: number;
  title: string;
  unitsSold: number[];
  selectedLabel: string;
  maxChart?: number;
  isTotal?: boolean;

  salesAtDiscount?: number;
  salesAtCost?: number;
  salesAtNormal?: number;
  discountPrice?: number;

  availableStock?: number;
}

const FinancialDetails: React.FC<IProps> = ({
  costs,
  iconName,
  sales,
  title,
  selectedLabel,
  maxChart,
  unitsSold,
  isTotal,
  salesAtCost,
  salesAtDiscount,
  salesAtNormal,
  discountPrice,
  availableStock
}) => {
  return (
    <div className="flex flex-col flex-1 px-xl py-lg">
      <div className="flex mb-xl items-center">
        <Icon name={iconName} />
        <Text1Light className="ml-md">{title}</Text1Light>
      </div>
      <div className="flex items-center text-grey-medium flex-wrap">
        {isTotal && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.costs}</Text4Light>
            <Text1Bold>€{Math.abs(costs)}</Text1Bold>
          </div>
        )}
        <div className="flex flex-col mr-lg mb-lg">
          <Text4Light>{content.sales}</Text4Light>
          <Text1Bold>
            {isTotal ? "€" : ""}
            {sales}
          </Text1Bold>
        </div>
        {salesAtNormal != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.salesAtNormal}</Text4Light>
            <Text1Bold>
              {isTotal ? "€" : ""}
              {salesAtNormal}
            </Text1Bold>
          </div>
        )}
        {salesAtDiscount != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>
              {content.salesAtDiscount(10 - (discountPrice || 0))}
            </Text4Light>
            <Text1Bold>
              {isTotal ? "€" : ""}
              {salesAtDiscount}
            </Text1Bold>
          </div>
        )}
        {salesAtCost != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.salesAtCost}</Text4Light>
            <Text1Bold>
              {isTotal ? "€" : ""}
              {salesAtCost}
            </Text1Bold>
          </div>
        )}
        {availableStock != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.availableStock}</Text4Light>
            <Text1Bold>{availableStock}</Text1Bold>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-xxl items-center">
        <Text4Bold className="text-grey-medium">
          {isTotal ? content.unitsSold : content.unitsSold2}
        </Text4Bold>
        <Value
          size="sm"
          suffix="€"
          value={unitsSold.length > 0 ? unitsSold[unitsSold.length - 1] : 0}
        />
      </div>

      <Chart
        selectedLabel={selectedLabel}
        max={maxChart || 1000}
        min={-100}
        data={[
          { label: "9am", value: 0 },
          { label: "12pm", value: 0 },
          { label: "6pm", value: 0 },
          { label: "7pm", value: 0 }
        ]
          .map((d, i) => ({
            ...d,
            value: unitsSold[i] || 0,
            include: unitsSold[i] != undefined
          }))
          .filter(f => f.include)}
      />
    </div>
  );
};

export default FinancialDetails;
