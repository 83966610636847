export default {
  title: "Trucks'n'Tricks",
  name: "Name",
  nameDesc: "How should we call you?",
  room: "Room",
  roomDesc: "Enter the room name",
  password: "Password",
  passwordDesc: "Enter password",
  cta: "Start the game",
  joining: "Joining"
};
