export default {
  additionalStock: "Aditional production",
  availableStock: "Available stock",
  totalStock: "Total stock",
  initialStock: "Initial production",
  tag: "Start of the day",
  title: "Inventory overview",
  unitsSold: "Units sold over time",
  salesAtCostPrice: "Units sold at cost price (5€)",
  salesAtDiscountPrice: (discount: number) => `Units sold at markdown price (${discount}€)`,
  salesAtNormalPrice: "Units sold at normal price (10€)"
};
