import React from "react";
import content from "./distribution.content";
import styled, { css } from "styled-components";
import { Text1Bold, Text3Bold, Text4Light } from "@metyis-ds/typography";
import { DESTINATIONS, IDestination } from "../../constants";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

interface IProps {
  destinations: any;
  onDestinationsChange: (destinations: any) => void;
}

const StyledDropZone = styled.div<{
  second?: boolean;
  active?: boolean;
  hasDestination?: boolean;
}>(
  ({ second, active, hasDestination, theme }) => css`
    height: 113px;
    width: 292px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 ${theme.spaceUnit.xxxl};
    border-radius: 17px;
    border: 2px dashed
      ${second ? theme.colors.green.light : theme.colors.yellow.light};
    color: ${second ? theme.colors.green.medium : theme.colors.yellow.medium};
    transition: 0.3s all;

    ${active &&
    css`
      border-color: ${second
        ? theme.colors.green.medium
        : theme.colors.yellow.medium};
      background-color: ${second
        ? theme.colors.green.light
        : theme.colors.yellow.light};
    `}
    ${hasDestination &&
    css`
      padding: 0;
      border: 2px solid
        ${second ? theme.colors.green.medium : theme.colors.yellow.medium};
      background-color: transparent;
      color: ${theme.colors.grey.dark};

      ${Destination} {
        border: unset;
        cursor: unset;
        box-shadow: none;

        &:hover {
          border: unset;
          box-shadow: none;
        }
      }
    `}
  `
);

const Destination = styled.div<{ dragging?: boolean }>(
  ({ dragging, theme }) => css`
    height: 109px;
    display: flex;
    justify-content: center;
    padding-left: ${theme.spaceUnit.lg};
    padding-right: ${theme.spaceUnit.xs};
    padding-top: ${theme.spaceUnit.xs};
    padding-bottom: ${theme.spaceUnit.xs};
    background-color: ${theme.colors.white};
    box-shadow: 0px 4px 30px -15px rgba(0, 0, 0, 0.3);
    border-radius: ${theme.spaceUnit.md};
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s all;

    &:hover {
      border-color: ${theme.colors.grey.light};
      box-shadow: 0px 4px 40px -10px rgba(0, 0, 0, 0.4);
    }

    ${dragging &&
    css`
      box-shadow: unset;
      border-color: ${theme.colors.grey.medium};
      border: 1px dashed;
      border-radius: 12px;
      opacity: 0.4;

      &:hover {
        border-color: ${theme.colors.grey.medium};
        box-shadow: unset;
      }
    `}
  `
);

const StyledDestination: React.FC<{ destination: IDestination }> = ({
  destination
}) => {
  const Illustration = destination.illustration;
  return (
    <div className={"flex justify-between w-full"}>
      <div className="flex flex-col justify-center pr-4">
        <Text1Bold className={"mb-1"}>{destination.name}</Text1Bold>
        <Text4Light>{destination.description}</Text4Light>
      </div>
      <Illustration className={"h-full"} style={{ width: 90 }} />
    </div>
  );
};

const DestinationDropZone: React.FC<{
  second?: boolean;
  destination?: IDestination;
  onClick?: () => void;
}> = ({ second, destination, onClick }) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "destination",
      drop: () => ({
        name: !second ? "Destination1" : "Destination2",
        second
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    []
  );

  const isActive = canDrop && isOver;

  return (
    <StyledDropZone
      ref={drop}
      second={second}
      active={isActive}
      hasDestination={!!destination}
    >
      {destination ? (
        <Destination onClick={onClick}>
          <StyledDestination destination={destination} />
        </Destination>
      ) : (
        <div className="px-8">
          <Text3Bold>{content.dragZoneText}</Text3Bold>
        </div>
      )}
    </StyledDropZone>
  );
};

const DestinationDrag: React.FC<{
  destination: IDestination;
  onChange: (second: boolean, destination: IDestination, isClick?: boolean) => void;
}> = ({ destination, onChange }) => {
  const [{ dragging }, drag] = useDrag(
    () => ({
      type: "destination",
      item: destination.name,
      end(item, monitor) {
        const dropResult = monitor.getDropResult() as { second: boolean };
        if (item && dropResult) {
          onChange(dropResult.second, destination);
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        dragging: monitor.isDragging()
      })
    }),
    [destination.name, onChange]
  );

  return (
    <Destination ref={drag} dragging={dragging} onClick={() => onChange(false, destination, true)}>
      <StyledDestination destination={destination} />
    </Destination>
  );
};

const DestinationDrop: React.FC<IProps> = ({
  destinations,
  onDestinationsChange
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {/* <DndProvider backend={TouchBackend}> */}
      <div>
        <div className={"flex justify-center items-center"}>
          <DestinationDropZone destination={destinations[0]} onClick={() => onDestinationsChange([null, destinations[1]])} />
          <DestinationDropZone second destination={destinations[1]} onClick={() => onDestinationsChange([destinations[0], null])}/>
        </div>
        <div className={"flex justify-center items-center m-4"}>
          <div className={"grid grid-cols-3 gap-4"}>
            {DESTINATIONS.map(d => (
              <DestinationDrag
                destination={d}
                key={d.name}
                onChange={(second, destination, clicked) => {
                  const result = [...destinations];
                  if(clicked) {
                    const nullIndex = result.indexOf(null)
                    if(nullIndex !== -1) {
                      result[nullIndex] = destination
                    } else {
                      result[0] = destination
                    }
                  } else {
                    result[second ? 1 : 0] = destination;
                  }
                  onDestinationsChange(result);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default DestinationDrop;
