import { Title2Bold, Title2Light, Body1Bold, Body1Light } from "@metyis-ds/typography";
import React from "react";
import IllusGirl from "../../assets/illustrations/welcome/manager.svg";
import LeftPanel, { ILeftPanelProps } from "./LeftPanel";

interface IDefaultLeftPanelProps extends ILeftPanelProps {
  title?: string;
  subtitle?: string;
  textColor?: string;
}
const DefaultLeftPanel: React.FC<IDefaultLeftPanelProps> = ({
  title,
  subtitle,
  textColor = "text-grey-dark",
  className
}) => {
  return (
    <LeftPanel className={className}>
      <Title2Bold className={`${textColor} mt-xxl hidden md:block`}>{title}</Title2Bold>
      <Body1Bold className={`${textColor} mt-xxl block md:hidden`}>{title}</Body1Bold>
      <Title2Light className={`${textColor} hidden md:block`}>{subtitle}</Title2Light>
      <Body1Light className={`${textColor} block md:hidden`}>{subtitle}</Body1Light>
      <IllusGirl className="absolute bottom-0 left-0 w-full" />
    </LeftPanel>
  );
};

export default DefaultLeftPanel;
