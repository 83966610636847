import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Switch, Route } from "@components/router";
import DistributionSetup from "./DistributionSetup";
import stateBusiness, { EPages } from "./state";
import { authState } from "@state/auth";
import api from "@api";

const Routes = () => {
  const page = useRecoilValue(stateBusiness);
  const auth = useRecoilValue(authState);

  const getData = async () => {
    // const { data, status } = await api.postOverview({
    //   gameid: auth.gameid,
    //   userid: auth.userid
    // });
    // if (status == 200) {
    //   console.log(data);
    //   setData({
    //     production_overview: data.production_overview,
    //     financial_overview: data.costs
    //   });
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.DISTRIBUTION_SETUP}
        Component={DistributionSetup}
      />
    </Switch>
  );
};

export default Routes;
