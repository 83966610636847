import React, { useEffect, useState } from "react";
import { Title2Medium } from "@metyis-ds/typography";
import Carousel from "@components/carousel/Carousel";
import ContentLayout from "@components/layouts/ContentLayout";
import Layout from "@components/layouts/Layout";
import content from "./Explain.content";
import { XLButton } from "@metyis-ds/button";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Slide1 from "@public/carousel1.png";
import Slide2 from "@public/carousel2.png";
import Slide3 from "@public/carousel3.png";
import stateBusiness from "./state";
import api from "@api";
import { authState } from "@state/auth";

const BusinessExplain = () => {
  const auth = useRecoilValue(authState);
  const [canStartGame, setCanStartGame] = useState(false);
  const setPage = useSetRecoilState(stateBusiness);

  const getScores = async () => {
    const { data, status } = await api.postScores({
      gameid: auth.gameid,
      userid: auth.userid
    });
  }

  const checkIfGameStarted = async () => {
    const { data, status } = await api.postCheckStartGame({
      gameid: auth.gameid
    });
    if(data) {
      setCanStartGame(data.canBeStarted);
    }
  }
  useEffect(() => {
    if(auth) {
      let inter = setInterval(() => {
        checkIfGameStarted();
      }, 2000);
      return () => {
        clearInterval(inter);
      }
    }
  }, [auth])
  
  const handleCta = () => {
    setPage({ page: 1 });
  };

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-blue-gradient"
      />
      <ContentLayout hideHeader>
        <div className="pr-15 px-5 pt-10 mb-md">
          <Title2Medium className="mb-8">{content.title}</Title2Medium>
          <Carousel
            itemLabels={[
              content.firstParagraph,
              content.secondParagraph,
              content.thirdParagraph
            ]}
          >
            <div className="flex justify-center">
              <img src={Slide1} style={{ maxWidth: "80%" }} />
            </div>
            <div className="flex justify-center">
              <img src={Slide2} style={{ maxWidth: "80%" }} />
            </div>
            <div className="flex justify-center">
              <img src={Slide3} style={{ maxWidth: "80%" }} />
            </div>
          </Carousel>
        </div>
        <XLButton disabled={!canStartGame} onClick={handleCta}>{content.ctaText}</XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default BusinessExplain;
