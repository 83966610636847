import { Title2Bold, Title2Light } from "@metyis-ds/typography";
import React, { useEffect } from "react";
import * as confetti from "canvas-confetti";
import IllusGirl from "../../assets/illustrations/welcome/manager-win.svg";
import IllusCup from "../../assets/illustrations/welcome/cup.svg";
import LeftPanel, { ILeftPanelProps } from "./LeftPanel";

interface IDefaultLeftPanelProps extends ILeftPanelProps {
  title?: string;
  subtitle?: string;
  textColor?: string;
}
const WinnerLeftPanel: React.FC<IDefaultLeftPanelProps> = ({
  title,
  subtitle,
  textColor = "text-grey-dark",
  className
}) => {
  const renderCanvasConfetti = () => (
    <canvas
      className="absolute flex flex-col items-center" style={{"top": "0px", "left": "0px", height: "900px"}}
      id="confetti-canvas-left"
      height="911"
      width="511"
    />
  );
  useEffect(() => {
    confettiAux();
  }, []);
  const confettiAux = () => {

    var myCanvas = document.querySelector("#confetti-canvas-left");
    var myConfetti = confetti.create(myCanvas, {
      useWorker: true
    });

    var end = Date.now() + (15 * 1000);
    var colors = ['#ffff00', '#ffffff'];

    (function frame(){
      myConfetti({ 
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      myConfetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  }
  return (
    <LeftPanel className={className}>
      <IllusGirl className="absolute bottom-0 left-0 w-full" />
      <IllusCup className="absolute bottom-0 left-0 w-full" />
      {renderCanvasConfetti()}
      <Title2Bold className={`${textColor} mt-xxl`}>{title}</Title2Bold>
      <Title2Light className={textColor}>{subtitle}</Title2Light>
    </LeftPanel>
  );
};

export default WinnerLeftPanel;
