import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import { stepState, ESteps } from "@state/step";
import pageState, { EPages, pageData } from "./state";
import {
  FinancialOverview,
  IFinancialSummary
} from "@templates/financial-overview";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import content from "./FinancialOverview.content";
import { EnumToArray, ITEMS } from "../../constants";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";
import api from "@api";
import { authState } from "@state/auth";

const FinancialOverviewPage = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const setPage = useSetRecoilState(pageState);

  const handleBackClick = useCallback(() => {
    setPage(EPages.PRODUCTION_OVERVIEW);
  }, []);

  const handleClick = useCallback(async () => {
    const { status: statusStep } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: ESteps.CHECKPOINT_3
    });
    if (statusStep == 200) {
      setStep(ESteps.CHECKPOINT_3);
    }
  }, []);

  const rawFinancial = useRecoilValue(pageData).financial_overview || [];

  const financialSummary: IFinancialSummary = {
    costs: rawFinancial.reduce((total, a) => (total += a), 0),
    profit: -rawFinancial.reduce((total, a) => (total += a), 0),
    sales: 0,
    title: "Total",
    selectedLabel: "9am",
    isTotal: true,
    financialInfo: rawFinancial.map((d, i) => {
      const item = ITEMS[i];
      return {
        costs: d,
        profit: -d,
        sales: 0,
        title: item.name,
        iconName: item.icon,
        selectedLabel: "9am",
        unitsSold: [-d]
      };
    })
  };

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-blue-sky-gradient"
      />
      <ContentLayout onBackClick={handleBackClick}>
        <FinancialOverview
          financialSummaryList={[financialSummary]}
          tag={{
            color: defaultTheme.colors.blue.medium,
            label: content.tagLabel
          }}
        />

        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.FINANCIAL_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default FinancialOverviewPage;
