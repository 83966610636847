import React from "react";
import { Tag } from "@metyis-ds/tag";
import { Title2Bold } from "@metyis-ds/typography";
import FinancialSummary, {
  IProps as IFinancialSummary
} from "@templates/financial-overview/FinancialSummary";
import content from "./financial.content";

export interface IProps {
  financialSummaryList: IFinancialSummary[];
  tag: {
    color: string;
    label: string;
  };
  maxChart?: number;
}

const FinancialOverviewTemplate: React.FC<IProps> = ({
  financialSummaryList,
  tag,
  maxChart
}) => (
  <div className="pb-xxl">
    <Tag backgroundColor={tag.color}>{tag.label}</Tag>
    <Title2Bold className="mt-xxs mb-xxxl">{content.title}</Title2Bold>
    {financialSummaryList.map(financialSummary => (
      <FinancialSummary
        maxChart={maxChart}
        key={financialSummary.title}
        {...financialSummary}
        isAlwaysExpanded={financialSummaryList.length === 1}
      />
    ))}
  </div>
);

export default FinancialOverviewTemplate;
