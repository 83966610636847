import styled, { css } from "styled-components";

interface IListAlternateProps {}

const ListAlternate = styled.ul<IListAlternateProps>(
  ({ theme }) => css`
    position: relative;

    li {
      position: relative;
      height: 154px;
      box-sizing: content-box;
      margin-top: ${theme.spaceUnit.xxs};
      z-index: 0;
      overflow: hidden;
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      gap: ${theme.spaceUnit.xl};

      :nth-child(odd) {
        direction: rtl;

        > * {
          direction: ltr;
        }
      }

      &.disabled {
        filter: grayscale(1);
      }
    }
  `
);

export default ListAlternate;
