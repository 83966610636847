import React from "react";
import City from "@assets/illustrations/welcome/city.svg";
import Metyis from "@assets/illustrations/metyis-logo.svg";
import MetyisBig from "@assets/illustrations/metyis-logo-big.svg";
import Title from "@assets/illustrations/welcome/title.svg";
import {
  StyledCloudContainer,
  StyledFactoryLarge,
  StyledTitle
} from "@components/illustrations/Illustrations";
import Cloud3 from "@illustrations/checkpoints/cloud3.svg";
import Cloud4 from "@illustrations/checkpoints/cloud4.svg";

const WelcomeTransition: React.FC = () => {
  return (
    <div className="relative flex flex-col bg-blue-sky-gradient h-screen">
      <div className="flex justify-center items-center my-xxxl">
        {/* <Metyis /> */}
      </div>
      <div className="flex justify-center items-center my-xxl">
        <StyledTitle>
          <Title />
        </StyledTitle>
      </div>
      <MetyisBig className="w-screen opacity-10 absolute top-0 right-0" />
      <StyledCloudContainer>
        <Cloud3
          className="absolute top-96 right-40"
          style={{ transform: "scale(1.5)" }}
        />
        <Cloud4
          className="absolute top-40 right-0"
          style={{ transform: "scale(2.5)" }}
        />
      </StyledCloudContainer>
      {/* <StyledGreenTruck /> */}
      <StyledFactoryLarge />
      <City className="h-1/2 absolute -bottom-1 -right-52" />
    </div>
  );
};

export default WelcomeTransition;
