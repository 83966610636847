import api from "@api";
import { Body1Light, Body2Bold, Body2Light, Text1Bold, Text1Light, Text2Bold, Text2Roman, Text3Light, Text4Light } from "@metyis-ds/typography";
import React, { useEffect, useState } from "react";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PDFIcon from "../../assets/illustrations/pdf2.svg";
import ChartIcon from "../../assets/illustrations/Chart.svg";

import IllusGirl from "../../assets/illustrations/welcome/manager.svg";
import { authState } from "@state/auth";
import { useRecoilValue } from "recoil";
import ImageModal from "@components/image-modal/ImageModal";
import { stepState } from "@state/step";

// const categories = ["Daily Sales", "Productivity", "Discount"]
// const granularities: { [key:string]: string[] } = {
//     "Daily Sales": ["Product", "Monthly", "Yearly", "Time of Day", "Histogram"],
//     "Productivity": [],
//     "Discount": []
// }

const GranularItem: React.FC<{ name: string, onClick: (e:any) => void, onChange: (e: any) => void, color?: string }> = ({ name, onChange, onClick, color }) => {
    const [{ dragging }, dragRef ] = useDrag(
        () => ({
            type: "granularity",
            item: name,
            collect: (monitor: DragSourceMonitor) => ({
                dragging: monitor.isDragging()
            }),
            end(item, monitor) {
                const dropResult = monitor.getDropResult() as { second: boolean };
                if (dropResult) {
                    onChange(name);
                }
            },
        })
    )
    return <div key={"key"+name} ref={dragRef} onClick={() => onClick(name)}
        style={{backgroundColor: color ? color : 'initial'}}
        className={`mx-xxs my-xxs text-sm rounded-md justify-center items-center cursor-pointer p-xs border-gray-600 border`}>
        <Text2Roman>{name}</Text2Roman>
    </div>
}

const GranularCombinator: React.FC<{ granularities: string[], removeItem: (d:string) => void; }>= ({ granularities, removeItem }) => {

    const auth = useRecoilValue(authState);
    const [discovery, setDiscovery] = useState<any>();

    const [currentSlide, setCurrentSlide] = useState<{ name: string, index: number } | null>();
    const [imageSrc, setImageSrc] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const checkCombination = async () => {
        const { data, status } = await api.postSlideDiscovery({
            granularities
        });
        if(!data.slides || data.slides.length == 0) {
            setDiscovery(null);
        } else {
            setDiscovery(data);
        }
    }
    const seeSlide = ( slideIndex: number, slide: any ) => {

        api.postSlide({
            gameid: auth.gameid,
            userid: auth.userid,
            index: slideIndex
        })
        .then((b) => b.blob())
        .then(async (blob:any) => {
            setImageSrc(URL.createObjectURL(blob));
            setShowModal(true);
            setCurrentSlide({ name: slide, index: slideIndex })
            // const dd = await blob.blob();
            // console.log(dd, blob)
            // // const link = document.createElement("a");
            // console.log(dd);
            // var file = new Blob([dd], {type: 'application/pdf'});
            // const url = URL.createObjectURL(file);
            // console.log("LINK ", url)
            // window.open(url);
        })
        

    }

    useEffect(() => {
        checkCombination();
    }, [granularities])
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: "granularity",
          drop: (d) => ({
            name: "dest,",
            source: d
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
          })
        }),
        []
    );
    
    const isActive = canDrop && isOver;
    return <>
        <div ref={drop} 
        className={`${isActive ? "border-4" : "border-2"} border-dashed border-gray-600 h-[200px] rounded-md my-sm`}>
            {granularities.length == 0 && <div className="w-full h-full flex flex-col justify-center items-center"><Text2Roman className="text-gray-500">Drop items here</Text2Roman></div>}
            <div className="flex flex-row flex-wrap">
                {granularities.map((d:any) => <Text2Roman 
                    onClick={() => removeItem(d)}
                    className={`mx-xxs my-xxs rounded-md justify-center items-center cursor-pointer p-xs border-gray-600 border`}>
                        {d}
                    </Text2Roman>)}
            </div>
        </div>

        <div className="flex flex-col">
            {discovery && discovery.slides.map((slide: string, index: number) => <div className="flex mt-md flex-row w-full border bg-gray-200 rounded-md justify-center items-center cursor-pointer py-2 px-4" onClick={() => seeSlide(discovery.slide_indexes[index], slide)}>
                <ChartIcon style={{fill: "black" }} />
                <div className="flex flex-col flex-1 ml-2 justify-center">
                    <Text2Roman className="text-black">{slide}</Text2Roman>
                    <Text3Light className="text-gray-400 text-gray-medium">Click to view information</Text3Light>
                </div>
            </div>)}
            {!discovery && <div className="flex flex-row justify-center items-center mt-md text-gray-400">No Slides for this combination</div>}
        </div>
        {/* <ManagerIllustration found={discovery != null} /> */}
        {imageSrc && currentSlide && <ImageModal slideIndex={currentSlide.index} grans={granularities} name={currentSlide.name} src={imageSrc} showModal={showModal} setShowModal={setShowModal}/>}
    </>
}
const ManagerIllustration: React.FC<{ found: boolean }> = ({ found }) => {
    return <>
        <IllusGirl className="absolute bottom-[-250px] left-[-100px] w-full" />
        {found && <div className="text-black bg-white-light rounded-md abolute h-[100px] w-[50%] flex flex-col p-md text-center" style={{ position: "absolute", right: "10px", bottom: "50px"}}>
            <Text2Roman>Congrats! You just found a piece of information! Click on the icon to see the data</Text2Roman>
        </div>}

    </>
}
const SlideExplorer = () => {
    const [pickedGranularities, setPickedGranularities] = useState<string[]>([]);
    const [category, setCategory] = useState<string | null>(null);
    const [categories, setCategories] = useState<{ [key: string]: string[] }>({})

    const step = useRecoilValue(stepState);

    const getPossibilities = async () => {
        const { data } = await api.getSlideHint();
        if(data) {
            setCategories(data.possibilities as any);
            const first = Object.keys(data.possibilities as any)[0];
            setCategory(first)
        }
    }

    useEffect(() => {
        getPossibilities();
    }, [])

    useEffect(() => {
        if(category) {
            setPickedGranularities([]);
        }
    }, [category])

    const addGranularity = (gran: string) => {
        setPickedGranularities((old:any) => {
            const oldIndex = old.findIndex((d: any) => d == gran);
            if(oldIndex == -1) {
                return old.slice().concat([gran]);
            } 
            return old;
        })
    }
    const removeGranularity = (gran: string) => {
        setPickedGranularities((old:any) => {
            const oldIndex = old.findIndex((d: any) => d == gran);
            const newArray = old.slice();
            if(oldIndex !== -1) {
                newArray.splice(oldIndex, 1)
            }
            return newArray;
        })
    }
    return <div>
        <div className="flex flex-row w-full text-white-light align-center justify-between my-lg">
            {Object.keys(categories).map(d => <div key={"cat-"+d} 
                className={`flex px-md py-sm justify-center items-center rounded-md cursor-pointer ${category == d && 'bg-gray-600'}`} 
                onClick={() => setCategory(d)}>
                <Text1Light>{d}</Text1Light>
            </div>)}
        </div>

        <DndProvider backend={HTML5Backend}>
            <div className="flex flex-col text-white-light my-lg min-h-[150px]">
                <Text2Roman>Available Granularities</Text2Roman>
                <div className="flex flex-row flex-wrap mx-[-12px]" >
                    {category && categories && categories[category]
                        .filter(d => !pickedGranularities.find(d2 => d2 == d))
                        .map(d => <GranularItem key={"gran"+d} onChange={(e) => addGranularity(e)} name={d} onClick={(e) => addGranularity(e)} />)}
                </div>
            </div>

            <div className="my-lg text-white-light">
                <Text2Roman>Selected Granularities (Click on chips to remove)</Text2Roman>
                <GranularCombinator granularities={pickedGranularities} removeItem={removeGranularity} />
            </div>
        </DndProvider>
    </div>
}



export default SlideExplorer