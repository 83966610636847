export default {
  ctaText: "Let's distribute",
  headerButtonText: "Operations",
  sidePanelSubtitle1: "Load each truck and select destination.",
  sidePanelSubtitle2: "Slide the product to set the quantity.",
  sidePanelSubtitle3: "You still have items to load onto trucks.",
  sidePanelSubtitle4: "Define the locations of each truck.",
  sidePanelTitle: "Start distribution!",
  title: "Drag & drop items into the trucks"
};
