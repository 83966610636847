export default {
  costs: "Costs",
  lastResults: "Last Results",
  profit: "Profit",
  sales: "Sales",
  title: "Financial overview",
  unitsSold: "Profit over time",
  unitsSold2: "Sales over time",
  totalCosts: "Total costs",
  totalProfit: "Total profit",
  totalSales: "Total sales",
  salesAtNormal: "Sales at full price (10€)",
  salesAtDiscount: (discount: number) => `Sales at markdown (${discount}€)`,
  salesAtCost: "Sales at cost (5€)",
  availableStock: "Available stock"
};
