import React from "react";
import classNames from "classnames";
import { Icon, IconNames } from "@metyis-ds/icon";
import { Text4Light } from "@metyis-ds/typography";

interface IIconBadgeProps {
  className?: string;
  disabled?: boolean;
  icon: IconNames;
  isSelected?: boolean;
  name: string;
  onClick?: (name: string) => void;
}

const IconBadge: React.FC<IIconBadgeProps> = ({
  className,
  disabled = false,
  icon,
  isSelected,
  name,
  onClick
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick?.(name);
    }
  };

  return (
    <div
      style={{ minWidth: "40px" }}
      className={classNames(className, "flex flex-col items-center relative")}
    >
      <div
        className={classNames("bg-white-light border rounded-2xl p-md w-fit", {
          "bg-overlay border-transparent": disabled,
          "border-white-light": !isSelected && !disabled,
          "border-grey-dark": isSelected,
          "cursor-pointer": !disabled
        })}
        onClick={handleClick}
      >
        <Icon name={icon} />
      </div>
      {disabled && (
        <div className="absolute bg-overlay rounded-2xl inset-0 z-10" />
      )}
      {isSelected && (
        <Text4Light className="absolute -bottom-md left-0 right-0 text-center whitespace-nowrap">
          {name.replaceAll("-", " ")}
        </Text4Light>
      )}
    </div>
  );
};

export default IconBadge;
