import React, { useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { XLButton } from "@metyis-ds/button";
import { ESteps, stepState } from "@state/step";
import { DistributionSetupTemplate } from "@templates/distribution-setup";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import ContentLayout from "@components/layouts/ContentLayout";
import Layout from "@components/layouts/Layout";
import content from "./DistributionSetup.content";
import api from "@api";
import { authState } from "@state/auth";
import OperationsSidePanel from "@templates/operations/OperationsSidePanel";
import { DESTINATIONS, IDestination, ITEMS } from "../../constants";
import { useEffect } from "react";
import { TruckData } from "src/api/data-contracts";
import { Body2Bold, Body2Light, Text1Bold, Text1Light } from "@metyis-ds/typography";

export interface IInventoryItem {
  num: number;
  name: string;
  image: any;
  numItems: number;
  truck1: number;
  truck2: number;
  inTruck1: boolean;
  inTruck2: boolean;
}

const DistributionSetup: React.FC = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  const [destinations, setDestinations] = useState<(IDestination | null)[]>([
    null,
    null
  ]);
  const [items, setItems] = useState<IInventoryItem[]>([]);

  const isCtaDisabled = useMemo(
    () =>
      destinations.find(d => d === null) !== undefined ||
      items.find(i => i.numItems > i.truck1 + i.truck2) ||
      (destinations[0] != null &&
        destinations[1] != null &&
        destinations[0].name == destinations[1].name) ||
      isCtaLoading,
    [destinations, isCtaLoading, items]
  );

  const getProducts = async () => {
    const { data, status } = await api.postGetProducts({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      setItems(
        (data.products || []).map((d, i) => {
          const item = ITEMS[i];
          return {
            num: item.num,
            name: item.name,
            image: item.Image,
            numItems: d,
            truck1: 0,
            truck2: 0,
            inTruck1: false,
            inTruck2: false
          };
        })
      );
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  const handleClick = async () => {
    saveDistribution();
  };

  const saveDistribution = async () => {
    try {
      setCtaIsLoading(true);
      const { status } = await api.postSetDistributionBatch1({
        gameid: auth.gameid,
        userid: auth.userid,
        distribution: truckData
      });
      if (status == 200) {
        const { status: statusStep } = await api.postStep({
          gameid: auth.gameid,
          userid: auth.userid,
          step: ESteps.CHECKPOINT_4
        });
        if (statusStep == 200) {
          setStep(ESteps.CHECKPOINT_4);
        }
      }
    } catch (e) {
      // handle error
    } finally {
      setCtaIsLoading(false);
    }
  };

  const parseDestination = (destination: IDestination | null) =>
    destination !== null
      ? DESTINATIONS.findIndex((d: IDestination) => d.name === destination.name)
      : 0;

  const truckData = useMemo<TruckData>(
    () => ({
      truck1: {
        item0: items.find(item => item.num === 0)?.truck1 ?? 0,
        item1: items.find(item => item.num === 1)?.truck1 ?? 0,
        item2: items.find(item => item.num === 2)?.truck1 ?? 0,
        item3: items.find(item => item.num === 3)?.truck1 ?? 0,
        market: parseDestination(destinations[0])
      },
      truck2: {
        item0: items.find(item => item.num === 0)?.truck2 ?? 0,
        item1: items.find(item => item.num === 1)?.truck2 ?? 0,
        item2: items.find(item => item.num === 2)?.truck2 ?? 0,
        item3: items.find(item => item.num === 3)?.truck2 ?? 0,
        market: parseDestination(destinations[1])
      }
    }),
    [items, destinations]
  );

  const panelSubtitle = useMemo(() => {
    const hasItemsLoaded = !!items.find(
      item => item.truck1 > 0 || item.truck2 > 0
    );
    const hasItemsSelected = !!items.find(i => i.inTruck1 || i.inTruck2);
    const stepSlide = hasItemsSelected && !hasItemsLoaded;
    const hasItemsAvailable = items.find(i => i.numItems > i.truck1 + i.truck2);
    const hasMarketsSelected = destinations.find(i => i == null) === undefined;

    if (stepSlide) {
      return content.sidePanelSubtitle2;
    } else if (hasItemsLoaded && hasItemsAvailable) {
      return content.sidePanelSubtitle3;
    } else if (!hasItemsAvailable && !hasMarketsSelected) {
      return content.sidePanelSubtitle4;
    } else if (hasMarketsSelected) {
      return content.sidePanelSubtitle5;
    } else {
      return content.sidePanelSubtitle1;
    }
  }, [content, items, destinations]);

  const header = (
    <div className="flex items-center justify-between w-full">
      <span><Text1Light>Current Time: </Text1Light><Text1Bold>10:00 AM</Text1Bold></span>
      <OperationsSidePanel disabledItems={["PRODUCTION_LINE", "WORKERS"]} />
    </div>
  );

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={panelSubtitle}
        className="bg-blue-sky-gradient"
      />
      <ContentLayout header={header}>
        {items && items.length == 4 && destinations ? (
          <DistributionSetupTemplate
            items={items}
            setItems={setItems}
            destinations={destinations}
            setDestinations={setDestinations}
          />
        ) : (
          <span></span>
        )}
        <XLButton disabled={isCtaDisabled} onClick={handleClick}>
          {content.ctaText}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default DistributionSetup;
