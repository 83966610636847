import React, { useCallback, useState } from "react";
import { InputSpinner } from "@metyis-ds/input-spinner";
import { Text1Bold, Text2Bold, Text4Light } from "@metyis-ds/typography";
import { IItem, ITEMS } from "../../constants";
import IconBadge from "@components/icon/IconBadge";
import content from "./production.content";
import { useEffect } from "react";

interface IProps {
  maxNumWorkers: number;
  num: number;
  onItemChange: (line: number, item: IItem) => void;
  onNumWorkersChange: (line: number, num: number) => void;
  defaultItem?: IItem;
  defaultWorkers?: number;
}

const ProductionLine: React.FC<IProps> = ({
  maxNumWorkers,
  num,
  onItemChange,
  onNumWorkersChange,
  defaultItem,
  defaultWorkers
}) => {
  const [selectedItem, setSelectedItem] = useState<IItem>();
  const [numWorkers, setNumWorkers] = useState(0);

  const handleItemChange = useCallback((itemName: string) => {
    const item = ITEMS.find(i => i.name === itemName) as IItem;

    setSelectedItem(item);
    onItemChange(num, item);
  }, []);

  useEffect(() => {
    if (defaultItem && defaultWorkers) {
      setSelectedItem(defaultItem);
      setNumWorkers(defaultWorkers);
    }
  }, [defaultItem, defaultWorkers]);

  const handleNumWorkersChange = useCallback((numWorkers: number) => {
    onNumWorkersChange(num, numWorkers);
  }, []);

  return (
    <li className="flex">
      <div className="self-end mr-xl">
        <Text2Bold
          className={
            num === 1
              ? "text-yellow-dark"
              : num === 2
              ? "text-blue-dark"
              : "text-green-dark"
          }
        >
          {content.productionLine} {num}
        </Text2Bold>
      </div>
      <div className="flex justify-evenly flex-grow">
        <div className="flex flex-col justify-between mr-lg xl:mr-xl">
          <div className="flex flex-col">
            <Text1Bold>{content.workers}</Text1Bold>
            <Text4Light>{content.howManyWorkers}</Text4Light>
          </div>
          <InputSpinner
            min={0}
            value={numWorkers}
            max={maxNumWorkers}
            defaultValue={defaultWorkers || 0}
            onChange={(e: number) => {
              handleNumWorkersChange(e);
              setNumWorkers(e);
            }}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col">
            <Text1Bold>{content.items}</Text1Bold>
            <Text4Light>{content.selectItem}</Text4Light>
          </div>
          <div className="flex flex-wrap">
            {ITEMS.map((item, index) => (
              <IconBadge
                key={item.name}
                className={
                  index !== ITEMS.length - 1 ? "mt-sm mr-sm xl:mr-md" : "mt-sm"
                }
                disabled={maxNumWorkers === 0}
                icon={item.icon}
                isSelected={selectedItem?.name === item.name}
                name={item.name}
                onClick={handleItemChange}
              />
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductionLine;
