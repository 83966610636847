import React from "react";
import styled, { css, useTheme } from "styled-components";

/* Illustrations */
import IllusMoon from "@assets/illustrations/progress/moon.svg";
import IllusSun from "@assets/illustrations/progress/sun.svg";
import IllusSunset from "@assets/illustrations/progress/sunset.svg";
import { Text2Bold } from "@metyis-ds/typography";
import { Flipped } from "react-flip-toolkit";

const StyledProgressBar = styled.div`
  position: absolute;
  top: 120px;
  left: 0px;
  z-index: 10;
  width: 100vw;
  padding: 0px 15%;
  display: flex;
  flex-direction: column;
`;
const StyledProgressLine = styled.div<{ inverted?: boolean }>(
  ({ inverted, theme }) => css`
    position: absolute;
    top: 0px;
    height: 1px;
    background-color: ${inverted ? "white" : theme.colors.blue.dark};
    width: 0%;
    transition: width 0.5s;
  `
);
const StyledProgressLineBackground = styled.div<{ inverted?: boolean }>(
  ({ inverted, theme }) => css`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 1px;
    width: 100%;
    flex: 1;
    background-color: ${inverted ? "#ffffff" : theme.colors.blue.dark};
    opacity: 0.3;
  `
);
const ProgressLinePointer = styled.div<{ inverted?: boolean }>(
  ({ inverted, theme }) => css`
    height: 18px;
    background-color: ${inverted ? "white" : theme.colors.blue.dark};
    position: absolute;
    width: 1px;
    bottom: -1px;
    transition: left 0.5s;
  `
);
const ProgressText = styled(Text2Bold)<{ inverted?: boolean }>(
  ({ inverted, theme }) => css`
    position: absolute;
    position: absolute;
    bottom: 20px;
    color: ${inverted ? "white" : theme.colors.blue.dark};
  `
);

interface IProgressBarLineProps {
  progress: number;
  text: string;
  showTick?: boolean;
  inverted?: boolean;
}
const ProgressBarLine: React.FC<IProgressBarLineProps> = ({
  progress,
  text,
  showTick = true,
  inverted
}) => {
  return (
    <div className="relative flex-1 mx-xl">
      <StyledProgressLineBackground inverted={inverted} />
      {showTick ? (
        <>
          <StyledProgressLine
            inverted={inverted}
            style={{ width: progress + "%" }}
          />
          <ProgressLinePointer
            inverted={inverted}
            style={{ left: progress + "%" }}
          />
          <Flipped flipId="progress-text">
            <ProgressText
              style={{ left: `calc(${progress}% - 20px)` }}
              inverted={inverted}
            >
              {text || ""}
            </ProgressText>
          </Flipped>
        </>
      ) : (
        <StyledProgressLine
          inverted={inverted}
          style={{ width: progress > 0 ? "100%" : "0%" }}
        />
      )}
    </div>
  );
};

interface IProgressBarProps {
  inverted?: boolean;
  text?: string;
  progress: number;
}
export const ProgressBar: React.FC<IProgressBarProps> = ({
  inverted,
  text = "",
  progress
}) => {
  const theme = useTheme();
  return (
    <StyledProgressBar>
      <div className="flex-1 flex justify-between items-center mb-xxl relative">
        <IllusMoon
          fill={
            progress > 0
              ? inverted
                ? theme.colors.white.light
                : theme.colors.blue.dark
              : theme.colors.grey.medium
          }
        />
        <ProgressBarLine
          inverted={inverted}
          text={text}
          progress={progress <= 50 ? (progress * 100) / 50 : 100}
          showTick={progress <= 50}
        />
        <IllusSun
          fill={
            progress > 50
              ? inverted
                ? theme.colors.white.light
                : theme.colors.blue.dark
              : theme.colors.grey.medium
          }
        />
        <ProgressBarLine
          inverted={inverted}
          text={text}
          progress={progress >= 50 ? ((progress - 50) * 100) / 50 : 0}
          showTick={progress > 50}
        />
        <IllusSunset
          fill={
            progress > 80
              ? inverted
                ? theme.colors.white.light
                : theme.colors.blue.dark
              : theme.colors.grey.medium
          }
        />
      </div>
    </StyledProgressBar>
  );
};
export default ProgressBar;
