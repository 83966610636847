import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { XLButton } from "@metyis-ds/button";
import { Text1Bold, Text1Light } from "@metyis-ds/typography";
import { ESteps, stepState } from "@state/step";
import { IItem, ITEMS } from "../../constants";
import { ProductionSetupTemplate } from "@templates/production-setup";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import content from "./ProductionSetup.content";
import OperationsSidePanel from "@templates/operations/OperationsSidePanel";
import { pageData } from "./state";
import { useEffect } from "react";
import api from "@api";
import { authState } from "@state/auth";

const ProductionSetup: React.FC = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const currentLines = useRecoilValue(pageData);
  const [parsedCurrentLines, setParsedCurrentLines] = useState<{
    [key: number]: { item: IItem; numWorkers: number };
  }>({});

  const [productionLines, setProductionLines] = useState<{
    [key: number]: { item: IItem; numWorkers: number };
  }>({});
  const [availableWorkers, setAvailableWorkers] = useState<number>();
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let newPr: {
      [key: number]: { item: IItem; numWorkers: number };
    } = {};
    currentLines.lines.forEach((d, i) => {
      newPr[i + 1] = {
        item: ITEMS[d],
        numWorkers: currentLines.workers[i]
      };
    });
    setParsedCurrentLines(newPr);
    setProductionLines(newPr);
  }, [currentLines]);

  const isCtaDisabled =
    availableWorkers !== 0 ||
    Object.values(productionLines).find(
      ({ item, numWorkers }) => !item || numWorkers == 0
    ) ||
    Object.keys(productionLines).length < 3 ||
    isCtaLoading;

  const handleClick = async () => {
    Object.keys(productionLines).forEach((key: any) => {
      if (productionLines[key].numWorkers == 0) {
        delete productionLines[key];
      }
    });
    try {
      setCtaIsLoading(true);
      const { status } = await api.postResetProductionBatch2({
        gameid: auth.gameid,
        userid: auth.userid,
        batch: productionLines
      });
      if (status == 200) {
        const { status: statusStep } = await api.postStep({
          gameid: auth.gameid,
          userid: auth.userid,
          step: ESteps.CHECKPOINT_7
        });
        if (statusStep == 200) {
          setStep(ESteps.CHECKPOINT_7);
        }
      }
    } catch (e) {
      // handle error
    } finally {
      setCtaIsLoading(false);
    }
  };

  const header = (
    <div className="flex items-center justify-between w-full">
      <span>
        <Text1Light>{content.headerLabel}</Text1Light>
        <Text1Bold>{availableWorkers}</Text1Bold>
      </span>
      <OperationsSidePanel disabledItems={["TRUCKS", "MARKETS"]} />
    </div>
  );
  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-blue-sky-gradient"
      />
      <ContentLayout header={header}>
        {Object.keys(parsedCurrentLines).length !== 0 ? (
          <ProductionSetupTemplate
            tag={content.tagLabel}
            title={content.title}
            handleProdLinesChange={setProductionLines}
            handleWorkersChange={setAvailableWorkers}
            prefilledProductionLines={parsedCurrentLines}
          />
        ) : (
          <span></span>
        )}
        <XLButton disabled={isCtaDisabled} onClick={handleClick}>
          {content.ctaText}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default ProductionSetup;
