import React from "react";
import * as d3 from "d3";
import styled, { css } from "styled-components";

const StyledSvg = styled.svg(
  ({ theme }) => css`
    text {
      ${theme.typography.text.text4bold};
      fill: ${theme.colors.grey.light};
      &.selected {
        fill: ${theme.colors.grey.medium};
      }
    }
    .line {
      stroke: ${theme.colors.green.medium};
      stroke-width: 3px;
    }
    .axis {
      .domain {
        display: none;
      }
      .tick {
        line {
          stroke: ${theme.colors.white.dark};
        }
      }
    }
    .grid {
      .domain {
        display: none;
      }
      .tick {
        line {
          stroke: ${theme.colors.white.dark};
        }

        &.zero {
          line {
            stroke: ${theme.colors.white.dark};
            stroke-dasharray: 5 5;
            stroke-width: 3;
          }
        }
      }
    }
    .background {
      fill: ${theme.colors.white.dark};
    }
  `
);

interface IData {
  label: string;
  value: number;
}
export class Chart extends React.Component<
  {
    data: IData[];
    selectedLabel: string;
    color?: string;
    max?: number;
    min?: number;
    height?: number;
  },
  {}
> {
  svgElement: any;
  svg: any;
  chartContainer: any;
  xScale: any;
  yScale: any;

  height: any;
  width: any;
  chartWidth: any;
  chartHeight: any;
  min: any;

  public margin = { top: 8, left: 0, right: 0, bottom: 25 };
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    setTimeout(() => {
      this.updateChart();
    }, 1000);
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.updateChart();
    }, 500);
  }
  updateChart() {
    // let firstNullIndex = this.props.data.findIndex(
    //   (d: any) => d && d.value == null
    // );
    // if (firstNullIndex > 0) {
    //   const data = this.props.data.slice(0, firstNullIndex);
    //   this.build(data);
    // } else {
    this.build(this.props.data);
    // }
  }
  getChartDimensions() {
    if (this.svgElement) {
      this.height = (
        this.svgElement.height || this.svgElement.parentNode.clientHeight
      ).baseVal.value;
      this.width = (
        this.svgElement.width || this.svgElement.parentNode.clientWidth
      ).baseVal.value;
      this.chartHeight = this.height - this.margin.top - this.margin.bottom;
      this.chartWidth = this.width - this.margin.right - this.margin.left;
    }
  }

  build(data: IData[]) {
    this.svg = d3.select(this.svgElement);
    this.getChartDimensions();
    this.chartContainer = appendOrSelect(
      this.svg,
      ".chart-container",
      "g",
      "chart-container"
    );

    this.chartContainer.attr(
      "transform",
      `translate(${this.margin.left},${this.margin.top})`
    );
    this.buildScales(data, this.props.min || 0, this.props.max || 0);
    this.buildBackground(this.props.selectedLabel);
    this.buildAxis();
    this.buildGrid(this.props.selectedLabel, this.props.max);
    if (data.filter((f: any) => f.value != null).length == 1) {
      this.buildPoints(data.filter((f: any) => f.value != null));
    } else {
      this.buildLine(data);
    }
  }
  buildScales(data: IData[], min: number, max: number) {
    let realMin = d3.min(data, d => d.value) || 0;
    this.min = realMin < 0 ? realMin : min;

    this.xScale = d3
      .scaleBand()
      .domain(["9am", "12pm", "6pm", "7pm"])
      .range([0, this.chartWidth])
      .paddingInner(0.6);
    this.yScale = d3
      .scaleLinear()
      .domain([realMin < 0 ? realMin : min, max])
      .range([this.chartHeight, 10]);
  }
  buildAxis() {
    const xAxisElement = appendOrSelect(
      this.chartContainer,
      ".x",
      "g",
      "axis x"
    );
    let xAxis = d3.axisBottom(this.xScale).tickSize(3);
    xAxisElement
      .attr("transform", "translate(0," + this.chartHeight + ")")
      .call(xAxis);
    return { xAxisElement, xAxis };
  }
  buildGrid(selected: string, max: number) {
    const grid = d3.axisLeft(this.yScale).ticks(3).tickValues([0]);

    appendOrSelect(this.chartContainer, ".grid", "g", "grid").call(
      grid.tickSize(-this.chartWidth).tickFormat((d: string) => "")
    );

    appendOrSelect(this.chartContainer, ".zero-label", "text", "zero-label")
      .attr("x", this.xScale("7pm"))
      .attr("y", this.yScale(0) - 6)
      .attr("text-anchor", "middle")
      .text("Zero Line");

    this.chartContainer
      .select(".grid")
      .selectAll(".tick")
      .classed("zero", (d: number) => d === 0);
    d3.selectAll(".tick")
      .select("text")
      .classed("selected", (d: string) => d == selected);
  }
  buildLine(data: any, data2?: any) {
    if (!data) {
      return;
    }
    const line = d3
      .line()
      .curve(d3.curveBasis)
      .defined((d: any) => d && d.value !== null)
      .x((d: any) =>
        d ? this.xScale(d.label) + this.xScale.bandwidth() / 2 : 0
      )
      .y((d: any) => (d && d.value != null ? this.yScale(d.value) : 0));

    const lineDom: any = this.chartContainer
      .selectAll(".line")
      .data(data2 ? [data, data2] : [data]);

    lineDom
      .enter()
      .append("path")
      .attr("class", "line")
      .merge(lineDom)
      .attr("d", (d: any) => line(d))
      .attr("fill", "none")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", function (this: any) {
        const totalLength = d3.select(this).node().getTotalLength();
        return `${totalLength} ${totalLength}`;
      })
      .style("stroke-dashoffset", function strokeDashOffset(this: any) {
        const totalLength = d3.select(this).node().getTotalLength();
        return totalLength;
      })
      .transition()
      .duration(1000)
      .style("stroke-dashoffset", "0px");
  }
  buildPoints(data: any) {
    const circle = this.chartContainer.selectAll(".circle").data(data);

    circle
      .enter()
      .append("circle")
      .attr("class", "circle")
      .attr("r", 5)
      .attr(
        "cx",
        (d: any) => this.xScale(d.label) + this.xScale.bandwidth() / 2
      )
      .attr("cy", (d: any) => this.yScale(d.value))
      .attr("fill", "#ED5B41");
  }
  buildBackground(selected: string) {
    const background = appendOrSelect(
      this.chartContainer,
      ".background",
      "rect",
      "background"
    );

    background
      .attr("width", this.xScale.bandwidth)
      .attr("x", this.xScale(selected))
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("y", 1)
      .attr("height", this.height - 10)
      .attr("fill-opacity", 0)
      .transition()
      .duration(500)
      .delay(500)
      .attr("fill-opacity", 1);
  }
  render() {
    return (
      <StyledSvg
        ref={node => {
          this.svgElement = node;
        }}
        width="100%"
        height={this.props.height || 146}
      ></StyledSvg>
    );
  }
}
const appendOrSelect = (
  container: any,
  selector: any,
  element: any,
  classString: string
) => {
  let result = container.select(selector);
  if (result.empty()) {
    result = container.append(element).attr("class", classString);
  }
  return result;
};
