import React from "react";
import { Icon, IconNames } from "@metyis-ds/icon";
import { Text2Roman, Text4Bold, Text4Light } from "@metyis-ds/typography";

interface IData {
  item: IconNames;
  label: string;
  value: number
}

export interface IProps {
  data: IData[];
  time: string;
  title: string;
}

const ActionsSummaryItem: React.FC<IProps> = ({ data, time, title }) => {
  return (
    <div className="p-xl">
      <div className="flex justify-between mb-md">
        <Text2Roman>{title}</Text2Roman>
        <Text4Bold>{time}</Text4Bold>
      </div>
      <div className="flex justify-around w-full">
        {data.filter(f => f.value > 0).map(dataItem => (
          <span key={dataItem.item} className="flex items-center">
            <Icon name={dataItem.item} />
            <Text4Light>{dataItem.label}</Text4Light>
          </span>
        ))}
      </div>
    </div>
  );
};

export default ActionsSummaryItem;
