export default {
  ctaText: "Let's produce",
  headerLabel: "Available workers: ",
  sidePanelSubtitle: "You have an opportunity to adjust the remaining production.",
  sidePanelTitle: "Adjustments!",
  tagLabel:
    "Production is reduced by 10% if workers are changed and 15% if items are changed",
  title:
    "Opportunity to make production adjustments"
};
