import React, { forwardRef } from "react";
import classnames from "classnames";
import { Body2Bold, Text4Light } from "@metyis-ds/typography";
import ActionsSummaryItem from "./ActionsSummaryItem";
import IllusChef from "@illustrations/common/chef-male.svg";
import IllusProdLine from "@illustrations/common/prod-line.svg";
import IllusGreenTruck from "@illustrations/common/truck-green.svg";
import IllusBlueTruck from "@illustrations/common/truck-blue.svg";
import IllusMetropolis from "@illustrations/locations/metropolis.svg";
import IllusMountains from "@illustrations/locations/mountains.svg";
import content from "./actions.content";

// const mockData = {
//   lines: [
//     {
//       time: "05:00",
//       title: "First production",
//       data: [
//         {
//           item: "ice-cream",
//           label: "04 workers"
//         },
//         {
//           item: "cheese",
//           label: "03 workers"
//         },
//         {
//           item: "sushi",
//           label: "03 workers"
//         }
//       ]
//     },
//     {
//       time: "11:00",
//       title: "Second production",
//       data: [
//         {
//           item: "ice-cream",
//           label: "04 workers"
//         },
//         {
//           item: "cheese",
//           label: "03 workers"
//         },
//         {
//           item: "sushi",
//           label: "03 workers"
//         }
//       ]
//     },
//     {
//       time: "13:30",
//       title: "Adjustment production",
//       data: [
//         {
//           item: "ice-cream",
//           label: "04 workers"
//         },
//         {
//           item: "cheese",
//           label: "03 workers"
//         },
//         {
//           item: "sushi",
//           label: "03 workers"
//         }
//       ]
//     }
//   ]
// };

export interface IActionsSummary {
  data: {
    time: string;
    title: string;
    data: {
      item: string;
      label: string;
      value: number;
    }[];
  }[];
  financialInfo?: number;
  numItems?: number;
  soldItems?: number;
  producedItems?: number;
}

interface IProps extends IActionsSummary {
  className?: string;
  id: string;
  type: "production" | "distribution" | "location";
}

const ActionsSummary = forwardRef<HTMLDivElement, IProps>(
  (
    {
      className,
      financialInfo,
      soldItems,
      producedItems,
      id,
      numItems,
      type,
      data
    },
    ref
  ) => {
    let title;
    let subtitle;
    let illustration;

    switch (type) {
      case "production":
        title = content.productionLines;
        subtitle = content.productionLinesInfo(
          numItems as number,
          financialInfo as number
        );
        illustration = (
          <>
            <IllusProdLine
              className="absolute h-auto"
              style={{ bottom: "-5%", right: "5%", width: "100%" }}
            />
            <IllusChef
              className="absolute bottom-0 w-2/5 h-auto"
              style={{ left: "-16%", maxHeight: "80%" }}
            />
          </>
        );

        break;
      case "distribution":
        title = content.distribution;
        subtitle = content.distributionInfo(numItems as number);
        illustration = (
          <>
            <IllusBlueTruck
              className="absolute h-auto"
              style={{ bottom: "20%", right: "-2%", width: "95%" }}
            />
            <IllusGreenTruck
              className="absolute h-auto"
              style={{ bottom: "-5%", right: "20%", width: "130%" }}
            />
          </>
        );
        break;
      case "location":
        title = content.locations;
        subtitle = content.locationsInfo(
          financialInfo as number,
          (soldItems as number) / (producedItems as number)
        );
        illustration = (
          <>
            <IllusMountains
              className="absolute h-auto"
              style={{ bottom: "10%", left: "-15%", width: "70%" }}
            />
            <IllusMetropolis
              className="absolute h-auto"
              style={{ bottom: "-10%", right: "-45%", width: "110%" }}
            />
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div
        className={classnames(
          "flex border-2 border-white-dark rounded-md",
          className
        )}
        style={{ minHeight: "400px" }}
        id={id}
        ref={ref}
      >
        <div className="flex w-2/6 relative overflow-hidden">
          <div className="flex flex-col p-xl">
            <Body2Bold className="mb-xs">{title}</Body2Bold>
            <Text4Light>{subtitle}</Text4Light>
          </div>
          {illustration}
        </div>

        <div className="bg-white-dark w-px" />

        <div className="flex flex-col flex-1 w-4/6">
          {data.map((line, index) => (
            <React.Fragment key={line.title}>
              <ActionsSummaryItem {...line} />
              {index < data.length - 1 && (
                <div className="bg-white-dark w-full h-px" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
);

export default ActionsSummary;
