import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Switch, Route } from "@components/router";
import DistributionSetup from "./DistributionSetup";
import stateBusiness, { EPages } from "./state";

const Routes = () => {
  const page = useRecoilValue(stateBusiness);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.DISTRIBUTION_SETUP}
        Component={DistributionSetup}
      />
    </Switch>
  );
};

export default Routes;
