import Country from "./assets/illustrations/destinations/d_country.svg";
import Desert from "./assets/illustrations/destinations/d_desert.svg";
import Historic from "./assets/illustrations/destinations/d_historic.svg";
import Metropolis from "./assets/illustrations/destinations/d_metropolis.svg";
import Mountain from "./assets/illustrations/destinations/d_mountain.svg";
import Sea from "./assets/illustrations/destinations/d_sea.svg";
import Cheese from "./assets/illustrations/distribution/Cheese.svg";
import Pizza from "./assets/illustrations/distribution/Pizza.svg";
import Coffee from "./assets/illustrations/distribution/Coffee.svg";
import IceCream from "./assets/illustrations/distribution/IceCream.svg";
import Sushi from "./assets/illustrations/distribution/Sushi.svg";
import Burger from "./assets/illustrations/distribution/Burger.svg";

export interface IItem {
  icon: string;
  name: string;
  num: number;
  Image: any;
}

const ICE_CREAM = {
  icon: "ice-cream",
  name: "Ice cream",
  num: 0,
  Image: IceCream
};

const SUSHI = {
  icon: "sushi",
  name: "Sushi",
  num: 1,
  Image: Sushi
};

const PIZZA = {
  icon: "pizza",
  name: "Pizza",
  num: 2,
  Image: Pizza
};

const COFFEE = {
  icon: "hot-cocoa",
  name: "Hot chocolate",
  num: 3,
  Image: Coffee
};

export const ITEMS = [ICE_CREAM, SUSHI, PIZZA, COFFEE];

export interface IDestination {
  name: string;
  illustration: string;
  description: string;
}

const COUNTRY = {
  name: "Country",
  description: "A nice countryside town.",
  illustration: Country
};
const MOUNTAIN = {
  name: "Mountain",
  description: "It can get chilling in this little town up in the mountains.",
  illustration: Mountain
};
const METROPOLIS = {
  name: "Metropolis",
  description: "A big city that never sleeps.",
  illustration: Metropolis
};
const SEA = {
  name: "Sea",
  description: "The nice summer breeze attracts a lot of tourists.",
  illustration: Sea
};
const DESERT = {
  name: "Desert",
  description: "A forgotten town.",
  illustration: Desert
};
const HISTORIC = {
  name: "Historic",
  description: "A lot of old things to check out here.",
  illustration: Historic
};

export const DESTINATIONS = [
  COUNTRY,
  MOUNTAIN,
  METROPOLIS,
  SEA,
  DESERT,
  HISTORIC
];
const isNumber = (value:any) => isNaN(Number(value)) === false;

const enum_beautifier_map: {[key:string]: string} = {
  "INVENTORY_OVERVIEW": "Inventory Overview",
  "FINANCIAL_OVERVIEW": "Financial Overview",
  "PRODUCTION_OVERVIEW": "Production Overview",
  "ACTIONS": "My Actions",
  "FINAL_SCORE": "My Score"
}
export const EnumToArray = (enumme: any) => {
  return Object.keys(enumme)
      .filter(isNumber)
      .map(key => enum_beautifier_map[enumme[key]]);
}

export const MAX_WORKERS_PER_LINE = 5;
export const MAX_AVAILABLE_WORKERS = 10;
