import React, { useState } from "react";
import { Icon } from "@metyis-ds/icon";
import { MButton } from "@metyis-ds/button";
import { Title3Light } from "@metyis-ds/typography";
import ContentLayout from "@components/layouts/ContentLayout";
import SidePanel from "@components/side-panel/SidePanel";
import OperationsTemplate, {
  IOperationsTemplateProps
} from "./OperationsTemplate";
import content from "./OperationsSidePanel.content";

type IOperationsSidePanelProps = IOperationsTemplateProps;

const OperationsSidePanel: React.FC<IOperationsSidePanelProps> = ({
  ...props
}) => {
  const [show, setShow] = useState(false);

  const header = (
    <div className="flex items-center justify-end w-full">
      <Icon
        name="close"
        className="cursor-pointer"
        onClick={() => setShow(false)}
      />
    </div>
  );

  return (
    <div>
      <MButton name="arrow-right-simple" onClick={() => setShow(true)}>
        {content.buttonText}
      </MButton>
      {show && (
        <SidePanel>
          <ContentLayout header={header}>
            <div>
              <Title3Light className="mb-xl">{content.title}</Title3Light>
              <OperationsTemplate {...props} />
            </div>
          </ContentLayout>
        </SidePanel>
      )}
    </div>
  );
};

export default OperationsSidePanel;
