import { atom } from "recoil";

export enum EPages {
  OPERATIONS_OVERVIEW = 0,
  PRODUCTION_SETUP = 1
}

const state = atom({
  key: "5am-state",
  default: EPages.PRODUCTION_SETUP
});

export default state;
