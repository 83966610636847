import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import ActionsOverview from "./ActionsOverview";
import FinancialOverview from "./FinancialOverview";
import InventoryOverview from "./InventoryOverview";
import pageState, { EPages, pageData } from "./state";
import FinalScore from "./FinalScore";
import { authState } from "@state/auth";
import api from "@api";

const Routes = () => {
  const page = useRecoilValue(pageState);
  const [data, setData] = useRecoilState(pageData);
  const auth = useRecoilValue(authState);

  const getData = async () => {
    const { data, status } = await api.postOverview({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      // Request data
      const res = await api.postActions({
        gameid: auth.gameid,
        userid: auth.userid
      });

      if (status == 200) {
        const res2 = await api.postScores({
          gameid: auth.gameid,
          userid: auth.userid
        });

        setData({
          // production_overview: data.production_overview,
          // financial_overview: data.financial_overview,
          inventory_overview: data.inventory_overview,
          profit_overview: data.profit_overview,
          sales: data.sales_overview,
          running_sales: data.running_sales,
          running_sales_raw: data.running_sales_raw,
          running_profit: data.running_profit,
          actions: res.data,
          scores: res2.data,
          sales_normal: data.sales_normal,
          sales_discount: data.sales_discount,
          sales_cost: data.sales_cost,
          markdown: data.markdown
          // profit_overview: data.profit_overview
        });
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.INVENTORY_OVERVIEW}
        Component={InventoryOverview}
      />
      <Route
        routeValue={EPages.FINANCIAL_OVERVIEW}
        Component={FinancialOverview}
      />
      <Route routeValue={EPages.ACTIONS} Component={ActionsOverview} />
      <Route routeValue={EPages.FINAL_SCORE} Component={FinalScore} />
    </Switch>
  );
};

export default Routes;
