import React from "react";
import { XLButton } from "@metyis-ds/button";
import { Title3Light } from "@metyis-ds/typography";
import ContentLayout from "@components/layouts/ContentLayout";
import Layout from "@components/layouts/Layout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import OperationsTemplate from "@templates/operations/OperationsTemplate";
import content from "./OperationsOverview.content";
import { useSetRecoilState } from "recoil";
import state, { EPages } from "./state";

const OperationsOverview: React.FC = () => {
  const setPage = useSetRecoilState(state);

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.leftTitle}
        subtitle={content.leftSubtitle}
        textColor="text-white-light"
        className="bg-dark-sky-gradient"
      />
      <ContentLayout>
        <div>
          <Title3Light>{content.title}</Title3Light>
          <OperationsTemplate />
        </div>
        <XLButton onClick={() => setPage(EPages.PRODUCTION_SETUP)}>
          {content.cta}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default OperationsOverview;
