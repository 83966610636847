import React, { useState } from "react";
import { Body2Bold, Text2Bold, Text2Roman } from "@metyis-ds/typography";
import SlideExplorer from "./SlideExplorer"
// import SlideExplorer from "./ColorSlideExplorer"
import { authState } from "@state/auth";
import { useRecoilValue } from "recoil";

import ChartIcon from "../../assets/illustrations/Chart.svg";
import { Icon } from "@metyis-ds/icon";

export interface ILeftPanelProps {
  className?: string;
}
const LeftPanel: React.FC<ILeftPanelProps> = ({ className, children }) => {
  const auth = useRecoilValue(authState);
  const [searchingSlides, setSearchingSlides] = useState(false);
  return (
    <div className={`relative py-xxl px-xxxl ${searchingSlides ? `bg-dark-sky-gradient overflow-auto` : className}`}>
      <div className="flex flex-row w-full justify-between items-center">
        <Body2Bold className="text-white-light">Trucks 'n' Tricks</Body2Bold>
        {!searchingSlides && auth && auth.gameid && auth.gameid.indexOf("ONLINE") != -1 && <Body2Bold className="text-white-light cursor-pointer" onClick={() => setSearchingSlides(true)}>
          <div className="flex flex-row items-center">
            <Text2Roman className="mr-1">See Slides</Text2Roman>
            <ChartIcon style={{ height: "24px", fill: "white" }}/>
          </div>
        </Body2Bold>}
        {searchingSlides && <Body2Bold className="text-white-light cursor-pointer" onClick={() => setSearchingSlides(false)}>
          <div className="flex flex-row items-center">
            <Text2Roman className="mr-1">Close</Text2Roman>
            <Icon name="close" color="white" width={24} style={{ height: "24px" }}/>
          </div>
        </Body2Bold>}
      </div>
      {searchingSlides ? <SlideExplorer /> : children}
    </div>
  );
};

export default LeftPanel;
