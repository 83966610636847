import React, { useEffect, useState } from "react";
import { XLButton } from "@metyis-ds/button";
import { Body2Bold, Body2Light, Title2Medium } from "@metyis-ds/typography";
import Layout from "@components/layouts/Layout";
import ContentLayout from "@components/layouts/ContentLayout";
import content from "./Welcome.content";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import api from "@api";
import WelcomeTransition from "@routes/welcome/WelcomeTransition";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "@state/auth";
import { stepState } from "@state/step";

const Welcome = () => {
  const [showTransition, setShowTransition] = useState(true);
  const auth = useRecoilValue(authState);
  const [step, setStep] = useRecoilState(stepState);
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  const handleCta = async () => {
    setCtaIsLoading(true);
    const { data, status } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: step + 1
    });
    if (status === 200) {
      setStep(data.step);
    }
    setCtaIsLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTransition(false);
    }, 7000);
    return () => clearTimeout(timeout);
  }, []);

  return showTransition ? (
    <WelcomeTransition />
  ) : (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-blue-gradient"
      />
      <ContentLayout header={<div />}>
        <div className="overflow-hidden pr-15">
          <Title2Medium className="mb-8">{content.welcome}</Title2Medium>
          <Body2Light className="mb-6" as="p">
            {content.firstParagraph}
          </Body2Light>
          <Body2Light className="mb-6" as="p">
            {content.secondParagraph}
          </Body2Light>
          <Body2Light className="mb-6" as="p">
            {content.thirdParagraph}
          </Body2Light>
          <Body2Light className="mb-6" as="p">
            {auth && auth.gameid && auth.gameid.indexOf("_ONLINE") !== -1 ? content.fourthParagraphOnline : content.fourthParagraph}
          </Body2Light>
          <Body2Bold as="p">{content.fifthParagraph}</Body2Bold>
        </div>
        <XLButton name="ArrowRight" disabled={isCtaLoading} onClick={handleCta}>
          {content.ctaText}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default Welcome;
