import React from "react";
import ListAlternate from "@components/list/ListAlternate";
import { Body2Bold, Text3Light } from "@metyis-ds/typography";
import IllusCloudEffect from "@illustrations/business/cloud-effect.svg";
import IllusFirst from "@illustrations/business/first.svg";
import IllusSecond from "@illustrations/business/second.svg";
import IllusThird from "@illustrations/business/third.svg";
import IllusFourth from "@illustrations/business/fourth.svg";
import IllusFifth from "@illustrations/business/fifth.svg";
import IllusArrowLeft from "@illustrations/business/arrow-left.svg";
import IllusArrowRight from "@illustrations/business/arrow-right.svg";
import content from "./OperationsTemplate.content";
import classnames from "classnames";

export interface IOperationsTemplateProps {
  disabledItems?: (
    | "PRODUCTION_LINE"
    | "WORKERS"
    | "FOOD_ITEMS"
    | "TRUCKS"
    | "MARKETS"
  )[];
}

const OperationsTemplate: React.FC<IOperationsTemplateProps> = ({
  disabledItems = []
}) => {
  return (
    <ListAlternate>
      <li
        className={classnames("text-orange-dark", {
          disabled: disabledItems.includes("PRODUCTION_LINE")
        })}
      >
        <div className="relative">
          <IllusCloudEffect className="text-orange-light fill-current" />
          <IllusFirst className="absolute inset-0" />
        </div>
        <div className="flex justify-center items-end flex-col gap-xxs">
          <Body2Bold>{content.firstLineTitle}</Body2Bold>
          <Text3Light>{content.firstLineSubtitle}</Text3Light>
        </div>
      </li>
      <li
        className={classnames("text-pink-dark", {
          disabled: disabledItems.includes("WORKERS")
        })}
      >
        <div className="relative">
          <IllusCloudEffect className="text-pink-light fill-current" />
          <IllusSecond className="absolute inset-0" />
        </div>
        <div className="flex justify-center items-start flex-col gap-xxs">
          <Body2Bold>{content.secondLineTitle}</Body2Bold>
          <Text3Light>{content.secondLineSubtitle}</Text3Light>
        </div>
        <div className="flex items-center justify-center">
          <IllusArrowLeft className="fill-current" />
        </div>
      </li>
      <li
        className={classnames("text-yellow-dark", {
          disabled: disabledItems.includes("FOOD_ITEMS")
        })}
      >
        <div className="relative">
          <IllusCloudEffect className="text-yellow-light fill-current" />
          <IllusThird className="absolute  top-0 h-full" />
        </div>
        <div className="flex justify-center items-end flex-col gap-xxs">
          <Body2Bold>{content.thirdLineTitle}</Body2Bold>
          <Text3Light>{content.thirdLineSubtitle}</Text3Light>
        </div>
        <div className="flex items-center justify-center">
          <IllusArrowRight className="fill-current" />
        </div>
      </li>
      <li
        className={classnames("text-green-dark", {
          disabled: disabledItems.includes("TRUCKS")
        })}
      >
        <div className="relative">
          <IllusCloudEffect className="text-green-light fill-current" />
          <IllusFourth className="absolute  top-0 h-full" />
        </div>
        <div className="flex justify-center items-start flex-col gap-xxs">
          <Body2Bold>{content.fourthLineTitle}</Body2Bold>
          <Text3Light>{content.fourthLineSubtitle}</Text3Light>
        </div>
        <div className="flex items-center justify-center">
          <IllusArrowLeft className="fill-current" />
        </div>
      </li>
      <li
        className={classnames("text-blue-dark", {
          disabled: disabledItems.includes("MARKETS")
        })}
      >
        <div className="relative">
          <IllusCloudEffect className="text-blue-light fill-current" />
          <IllusFifth className="absolute top-0 h-full " />
        </div>
        <div className="flex justify-center items-end flex-col gap-xxs">
          <Body2Bold>{content.fifthLineTitle}</Body2Bold>
          <Text3Light>{content.fifthLineSubtitle}</Text3Light>
        </div>
        <div className="flex items-center justify-center">
          <IllusArrowRight className="fill-current" />
        </div>
      </li>
    </ListAlternate>
  );
};

export default OperationsTemplate;
