import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { XLButton } from "@metyis-ds/button";
import { Icon } from "@metyis-ds/icon";
import { InputSpinner } from "@metyis-ds/input-spinner";
import { defaultTheme } from "@metyis-ds/tokens";
import {
  Body1Bold,
  Text1Light,
  Text1Bold,
  Text3Light,
  Title3Light
} from "@metyis-ds/typography";
import { Value } from "@metyis-ds/value";
import { ESteps, stepState } from "@state/step";
import {
  FinancialOverviewSidePanel,
  IFinancialSummary
} from "@templates/financial-overview";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import ContentLayout from "@components/layouts/ContentLayout";
import Layout from "@components/layouts/Layout";
import content from "./Markdown.content";
import { authState } from "@state/auth";
import api from "@api";
import { DESTINATIONS, ITEMS } from "../../constants";
import { IPageData, pageData } from "./state";

const TableStyle = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  td {
    border-bottom: 2px solid #edf1f3;
    border-right: 2px solid #edf1f3;
  }

  td:first-child {
    border-left: 2px solid #edf1f3;
  }
  tr:first-child td {
    border-top: 2px solid #edf1f3;
  }
  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`;

interface IIventoryData {
  market: string;
  inventory: {
    name: string;
    iconName: string;
    inventory: number;
  }[];
}
const nullSummary: IFinancialSummary = {
  costs: 0,
  selectedLabel: "12pm",
  financialInfo: [],
  profit: 0,
  sales: 0,
  title: "Total"
};
const getPanels = (data: IPageData) => {
  const markets: IIventoryData[] = [];

  if (data && data.inventory_overview) {
    markets.push({
      market: "Total",
      inventory: ITEMS.map((d2, index) => {
        return {
          name: d2.name,
          iconName: d2.icon,
          inventory: data.inventory_overview
            ? data.inventory_overview.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0
        };
      })
    });
  }
  return markets;
};

const getMarkets = (rawData: IPageData) => {
  const markets: IFinancialSummary[] = [];
  if (rawData && rawData.sales) {
    rawData.sales.forEach((d, i) => {
      const totalSales = d.reduce(function (a, b) {
        return a + b;
      });

      if (totalSales > 0) {
        // Market is present
        markets.push({
          title: DESTINATIONS[i].name,
          selectedLabel: "6pm",
          profit: rawData.running_profit
            ? rawData.running_profit[2][i].reduce((a, b) => a + b)
            : 0,
          sales: rawData.sales
            ? rawData.sales[i].reduce(function (a, b) {
                return a + b;
              }) * 10
            : 0,
          costs:
            ((rawData.inventory_overview
              ? rawData.inventory_overview[i].reduce(function (a, b) {
                  return a + b;
                })
              : 0) +
              (rawData.sales
                ? rawData.sales[i].reduce(function (a, b) {
                    return a + b;
                  })
                : 0)) *
            5,
          financialInfo: ITEMS.map((item, index) => {
            return {
              iconName: item.icon,
              title: item.name,
              availableStock: rawData.inventory_overview
                ? rawData.inventory_overview[i][index]
                : 0,
              costs:
                ((rawData.inventory_overview
                  ? rawData.inventory_overview[i][index]
                  : 0) +
                  (rawData.sales ? rawData.sales[i][index] : 0)) *
                5,
              unitsSold: [
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[0][i][index]
                  : 0,
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[1][i][index]
                  : 0,
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[2][i][index]
                  : 0
              ],
              sales: rawData.sales ? rawData.sales[i][index] : 0,
              profit: rawData.running_profit
                ? rawData.running_profit[2][i][index]
                : 0,
              selectedLabel: "6pm"
            };
          }).filter(f => f.costs > 0)
        });
      }
    });
  }
  return markets;
};

const discounts = [10, 9, 8, 7, 6, 5];

const Markdown = () => {
  const setStep = useSetRecoilState(stepState);
  const [data, setData] = useRecoilState(pageData);
  const auth = useRecoilValue(authState);
  const [markdown, setMarkdown] = useState<number[]>([10, 10, 10, 10]);
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  const getData = async () => {
    const { data, status } = await api.postOverview({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      setData({
        inventory_overview: data.inventory_overview,
        sales: data.sales_overview,
        running_sales: data.running_sales,
        running_sales_raw: data.running_sales_raw,
        running_profit: data.running_profit
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let financialSummary: IFinancialSummary = nullSummary;

  const markets = getMarkets(data);

  if (data) {
    const costs =
      ((data.inventory_overview
        ? data.inventory_overview
            .reduce(function (a, b) {
              return a.concat(b);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : 0) +
        (data.sales
          ? data.sales
              .reduce(function (a, b) {
                return a.concat(b);
              })
              .reduce(function (a, b) {
                return a + b;
              })
          : 0)) *
      5;
    const sales = data.sales
      ? data.sales
          .reduce(function (a, b) {
            return a.concat(b);
          })
          .reduce(function (a, b) {
            return a + b;
          }) * 10
      : 0;
    financialSummary = {
      ...nullSummary,
      costs,
      sales,
      profit: sales - costs,
      isTotal: true,
      financialInfo: ITEMS.map((item, index) => {
        const costs =
          ((data.inventory_overview
            ? data.inventory_overview.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0) +
            (data.sales
              ? data.sales.reduce((total, a) => (total += a[index]), 0)
              : 0)) *
          5;
        const sales = data.sales
          ? data.sales.reduce((total, a) => (total += a[index]), 0) * 10
          : 0;

        return {
          iconName: item.icon,
          title: item.name,
          costs,
          sales,
          profit: sales - costs,
          unitsSold: [
            data.running_profit
              ? data.running_profit[0].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            data.running_profit
              ? data.running_profit[1].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            data.running_profit
              ? data.running_profit[2].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0
          ],
          selectedLabel: "6pm"
        };
      })
    };
  }

  const saveMarkdown = async () => {
    try {
      setCtaIsLoading(true);
      const disc = markdown.map(d => discounts.indexOf(d));
      const { status, data } = await api.postSetMarkdown({
        userid: auth.userid,
        gameid: auth.gameid,
        markdown: {
          item0: disc[0],
          item1: disc[1],
          item2: disc[2],
          item3: disc[3]
        }
      });
      if (status == 200) {
        const { status: statusStep } = await api.postStep({
          gameid: auth.gameid,
          userid: auth.userid,
          step: ESteps.CHECKPOINT_11
        });
        if (statusStep == 200) {
          setStep(ESteps.CHECKPOINT_11);
        }
      }
    } catch (e) {
    } finally {
      setCtaIsLoading(false);
    }
  };

  const header = (
    <div className="flex items-center justify-between w-full">
      <span>
        <Text1Light>{content.headerLabel}</Text1Light>
        <Text1Bold className="ml-xs">10€</Text1Bold>
      </span>
      <FinancialOverviewSidePanel
        financialSummaryList={[financialSummary, ...markets]}
        tag={{
          color: defaultTheme.colors.grey.medium,
          label: content.financialOverviewSidePanelTag
        }}
      />
    </div>
  );

  const panels = getPanels(data);

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-sunset-sky-gradient"
      />
      <ContentLayout header={header}>
        <div className="mb-xxxl">
          <Title3Light>{content.title}</Title3Light>

          {panels.map((d, index) => {
            return (
              <TableStyle
                key={index}
                width="100%"
                cellPadding="24"
                className="mt-xxl"
              >
                <tbody>
                  <tr style={{ height: "77px" }}>
                    <td width="33%">
                      <Body1Bold>{d.market}</Body1Bold>
                    </td>
                    <td align="center" width="33%">
                      <Text3Light>Current inventory</Text3Light>
                    </td>
                    <td align="center" width="33%">
                      <Text3Light>End-of-day price €</Text3Light>
                    </td>
                  </tr>

                  {d.inventory.map((inv, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="flex">
                            <Icon name={inv.iconName} />
                            <Text1Light className="ml-md">
                              {inv.name}
                            </Text1Light>
                          </div>
                        </td>
                        <td align="center">
                          <div className="w-fit">
                            <Value size="sm" value={inv.inventory} />
                          </div>
                        </td>
                        <td align="center">
                          <InputSpinner
                            dark
                            initialValue={10}
                            step={1}
                            min={5}
                            max={10}
                            onChange={e =>
                              setMarkdown(mark => {
                                const copy = mark.slice();
                                copy[index] = e;
                                return copy;
                              })
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TableStyle>
            );
          })}
        </div>
        <XLButton disabled={isCtaLoading} onClick={saveMarkdown}>
          {content.ctaText}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default Markdown;
