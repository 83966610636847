import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import pageState, { EPages, IPageData, pageData } from "./state";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import {
  InventoryOverview,
  IInventorySummary
} from "@templates/inventory-overview";
import content from "./InventoryOverview.content";
import { ITEMS, DESTINATIONS, EnumToArray } from "../../constants";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const getMarkets = (rawData: IPageData) => {
  const markets: IInventorySummary[] = [];
  if (rawData && rawData.inventory_overview) {
    rawData.inventory_overview.forEach((d, i) => {
      const total = d.reduce(function (a, b) {
        return a + b;
      });
      if (total > 0) {
        // Market is present
        markets.push({
          title: DESTINATIONS[i].name,
          availableStock: rawData.preinv
            ? rawData.preinv[i].reduce((total, a) => (total += a), 0)
            : 0,
          totalStock: total,
          unitsSold: [
            rawData.presales
              ? rawData.presales[i].reduce((total, a) => (total += a), 0)
              : 0
          ],
          inventoryData: ITEMS.map((item, index) => {
            const totalOfItem = rawData.inventory_overview
              ? rawData.inventory_overview[i][index]
              : 0;
            return {
              iconName: item.icon,
              title: item.name,
              additionalStock: 0,
              initialStock: totalOfItem,
              unitsSold: [0, rawData.presales ? rawData.presales[i][index] : 0],
              availableStock: rawData.preinv ? rawData.preinv[i][index] : 0,
              selectedLabel: "12pm"
            };
          }).filter(f => f.initialStock > 0)
        });
      }
    });
  }
  return markets;
};

const nullSummary: IInventorySummary = {
  availableStock: 0,
  inventoryData: [],
  title: "Total",
  totalStock: 0,
  unitsSold: [0]
};
const InventoryOverviewPage = () => {
  const setPage = useSetRecoilState(pageState);
  const rawInventory = useRecoilValue(pageData);

  const markets = getMarkets(rawInventory);

  let inventorySummary: IInventorySummary = nullSummary;

  if (rawInventory) {
    inventorySummary = {
      ...nullSummary,
      isTotal: true,
      totalStock: (rawInventory.inventory_overview || [])
        .reduce(function (a, b) {
          return a.concat(b);
        })
        .reduce(function (a, b) {
          return a + b;
        }),
      unitsSold: [
        0,
        (rawInventory.presales || [])
          .reduce(function (a, b) {
            return a.concat(b);
          })
          .reduce(function (a, b) {
            return a + b;
          })
      ],
      availableStock: (rawInventory.preinv || [])
        .reduce(function (a, b) {
          return a.concat(b);
        })
        .reduce(function (a, b) {
          return a + b;
        }),
      inventoryData: ITEMS.map((item, index) => {
        return {
          iconName: item.icon,
          title: item.name,
          additionalStock: rawInventory.production_overview
            ? rawInventory.production_overview[index]
            : 0,
          initialStock: rawInventory.inventory_overview
            ? rawInventory.inventory_overview.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0,
          unitsSold: [
            0,
            rawInventory.presales
              ? rawInventory.presales.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0
          ],
          availableStock: rawInventory.preinv
            ? rawInventory.preinv.reduce((total, a) => (total += a[index]), 0)
            : 0,
          selectedLabel: "12pm"
        };
      })
    };
  }

  const handleBackClick = useCallback(() => {
    setPage(EPages.PRODUCTION_OVERVIEW);
  }, []);

  const handleClick = useCallback(() => {
    setPage(EPages.FINANCIAL_OVERVIEW);
  }, []);

  return (
    <Layout>
      <DefaultLeftPanel
        className="bg-blue-sky-gradient"
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
      />
      <ContentLayout onBackClick={handleBackClick}>
        <InventoryOverview
          inventorySummaryList={[inventorySummary, ...markets]}
          tag={{
            color: defaultTheme.colors.blue.medium,
            label: content.tagLabel
          }}
          maxChart={100}
        />

        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.INVENTORY_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default InventoryOverviewPage;
