import React from "react";
import { Tag } from "@metyis-ds/tag";
import { Title2Bold } from "@metyis-ds/typography";
import InventorySummary, {
  IProps as IInventorySummary
} from "./InventorySummary";
import content from "./inventory.content";

interface IProps {
  inventorySummaryList: IInventorySummary[];
  maxChart?: number;
  tag: {
    color: string;
    label: string;
  };
}

const InventoryOverviewTemplate: React.FC<IProps> = ({
  inventorySummaryList,
  maxChart,
  tag
}) => (
  <div>
    <Tag backgroundColor={tag.color}>{tag.label}</Tag>
    <Title2Bold className="mt-xxs mb-xxxl">{content.title}</Title2Bold>
    {inventorySummaryList.map(inventorySummary => (
      <InventorySummary
        maxChart={maxChart}
        key={inventorySummary.title}
        {...inventorySummary}
      />
    ))}
  </div>
);

export default InventoryOverviewTemplate;
