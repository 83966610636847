import React from "react";
import { Icon, IconNames } from "@metyis-ds/icon";
import {
  Text1Light,
  Text1Bold,
  Text4Bold,
  Text4Light
} from "@metyis-ds/typography";
import { Value } from "@metyis-ds/value";
import content from "./inventory.content";
import { Chart } from "@components/chart/Chart";

export interface IProps {
  additionalStock?: number;
  salesAtNormalPrice?: number;
  salesAtDiscountPrice?: number;
  salesAtCostPrice?: number;
  discountPrice?: number;
  availableStock?: number;
  iconName: IconNames;
  initialStock?: number;
  totalStock?: number;
  title: string;
  unitsSold: number[];
  selectedLabel: string;
  maxChart?: number;
  isTotal?: boolean;
}

const FinancialDetails: React.FC<IProps> = ({
  additionalStock,
  salesAtCostPrice,
  salesAtDiscountPrice,
  salesAtNormalPrice,
  discountPrice,
  availableStock,
  iconName,
  initialStock,
  title,
  unitsSold,
  selectedLabel,
  maxChart,
  isTotal,
  totalStock
}) => {
  return (
    <div className="flex flex-col flex-1 px-xl py-lg">
      <div className="flex mb-xl items-center">
        <Icon name={iconName} />
        <Text1Light className="ml-md">{title}</Text1Light>
      </div>
      <div className="flex items-center text-grey-medium flex-wrap">
        {isTotal && initialStock != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.initialStock}</Text4Light>
            <Text1Bold>{initialStock}</Text1Bold>
          </div>
        )}
        {isTotal && totalStock != null && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.totalStock}</Text4Light>
            <Text1Bold>{totalStock}</Text1Bold>
          </div>
        )}
        {additionalStock != null && isTotal && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.additionalStock}</Text4Light>
            <Text1Bold>{additionalStock}</Text1Bold>
          </div>
        )}
        {salesAtNormalPrice != null && isTotal && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.salesAtNormalPrice}</Text4Light>
            <Text1Bold>{salesAtNormalPrice}</Text1Bold>
          </div>
        )}
        {salesAtDiscountPrice != null && isTotal && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>
              {content.salesAtDiscountPrice(10 - (discountPrice || 0))}
            </Text4Light>
            <Text1Bold>{salesAtDiscountPrice}</Text1Bold>
          </div>
        )}
        {salesAtCostPrice != null && isTotal && (
          <div className="flex flex-col mr-lg mb-lg">
            <Text4Light>{content.salesAtCostPrice}</Text4Light>
            <Text1Bold>{salesAtCostPrice}</Text1Bold>
          </div>
        )}
        {availableStock != null && (
          <div className="flex flex-col mb-lg">
            <Text4Light>
              {content.availableStock}
              {!isTotal && " in this location"}
            </Text4Light>
            <Text1Bold>{availableStock}</Text1Bold>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-xxl items-center">
        <Text4Bold className="text-grey-medium">{content.unitsSold}</Text4Bold>
        <Value size="sm" value={unitsSold[unitsSold.length - 1]} />
      </div>
      <Chart
        selectedLabel={selectedLabel}
        max={maxChart || 600}
        min={-10}
        data={[
          { label: "9am", value: 0 },
          { label: "12pm", value: 0 },
          { label: "6pm", value: 0 },
          { label: "7pm", value: 0 }
        ]
          .map((d, i) => ({
            ...d,
            value: unitsSold[i] || 0,
            include: unitsSold[i] != undefined
          }))
          .filter(f => f.include)}
      />
    </div>
  );
};

export default FinancialDetails;
