import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import FinancialOverview from "./FinancialOverview";
import InventoryOverview from "./InventoryOverview";
import state, { EPages, pageData } from "./state";
import { authState } from "@state/auth";
import api from "@api";

const Routes = () => {
  const page = useRecoilValue(state);
  const [data, setData] = useRecoilState(pageData);
  const auth = useRecoilValue(authState);

  const getData = async () => {
    const { data, status } = await api.postOverview({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      setData({
        // production_overview: data.production_overview,
        // financial_overview: data.financial_overview,
        inventory_overview: data.inventory_overview,
        sales: data.sales_overview,
        running_sales: data.running_sales,
        running_sales_raw: data.running_sales_raw,
        running_profit: data.running_profit
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.INVENTORY_OVERVIEW}
        Component={InventoryOverview}
      />
      <Route
        routeValue={EPages.FINANCIAL_OVERVIEW}
        Component={FinancialOverview}
      />
    </Switch>
  );
};

export default Routes;
