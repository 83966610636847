import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { XLButton } from "@metyis-ds/button";
import { Text1Bold, Text1Light } from "@metyis-ds/typography";
import { ESteps, stepState } from "@state/step";
import { IItem } from "../../constants";
import { ProductionSetupTemplate } from "@templates/production-setup";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import content from "./ProductionSetup.content";
import api from "@api";
import { authState } from "@state/auth";
import OperationsSidePanel from "@templates/operations/OperationsSidePanel";

const ProductionSetup: React.FC = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const [productionLines, setProductionLines] = useState<{
    [key: number]: { item: IItem; numWorkers: number };
  }>({});
  const [availableWorkers, setAvailableWorkers] = useState<number>();
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  const isCtaDisabled =
    availableWorkers !== 0 ||
    Object.values(productionLines).find(
      ({ item, numWorkers }) => !item || numWorkers == 0
    ) ||
    Object.keys(productionLines).length < 3 ||
    isCtaLoading;

  const handleClick = async () => {
    Object.keys(productionLines).forEach((key: any) => {
      if (productionLines[key].numWorkers == 0) {
        delete productionLines[key];
      }
    });

    setCtaIsLoading(true);
    const { status } = await api.postSetProductionBatch1({
      gameid: auth.gameid,
      userid: auth.userid,
      batch: productionLines
    });
    if (status === 200) {
      const { status: statusStep } = await api.postStep({
        gameid: auth.gameid,
        userid: auth.userid,
        step: ESteps.CHECKPOINT_2
      });
      if (statusStep == 200) {
        setStep(ESteps.CHECKPOINT_2);
      }
    }
    setCtaIsLoading(false);
  };

  const header = (
    <div className="flex items-center justify-between w-full">
      <span>
        <Text1Light>{content.headerLabel}</Text1Light>
        <Text1Bold>{availableWorkers}</Text1Bold>
      </span>
      <OperationsSidePanel disabledItems={["TRUCKS", "MARKETS"]} />
    </div>
  );

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        textColor="text-white-light"
        className="bg-dark-sky-gradient"
      />
      <ContentLayout header={header}>
        <ProductionSetupTemplate
          title={content.title}
          handleProdLinesChange={setProductionLines}
          handleWorkersChange={setAvailableWorkers}
        />
        <XLButton disabled={isCtaDisabled} onClick={handleClick}>
          {content.ctaText}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default ProductionSetup;
