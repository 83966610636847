import React, { useMemo } from "react";
import classnames from "classnames";
import { DataExpandablePanel } from "@metyis-ds/expandable-panel";
import FinancialDetails, { IProps as IFinancialInfo } from "./FinancialDetails";
import content from "./financial.content";

export interface IProps {
  costs: number;
  financialInfo: IFinancialInfo[];
  isAlwaysExpanded?: boolean;
  profit: number;
  sales: number;
  title: string;
  selectedLabel: string;
  maxChart?: number;
  isTotal?: boolean;

  salesAtDiscount?: number;
  salesAtCost?: number;
  salesAtNormal?: number;
  discountPrice?: number;
}

const FinancialOverview: React.FC<IProps> = ({
  costs,
  financialInfo,
  isAlwaysExpanded,
  profit,
  sales,
  maxChart,
  title,
  isTotal
}) => {
  const data = useMemo(
    () => [
      { prefix: "€", title: content.totalCosts, value: costs * -1 },
      { prefix: "€", title: content.totalSales, value: sales },
      { prefix: "€", title: content.totalProfit, value: profit }
    ],
    [costs, profit, sales]
  );

  return (
    <DataExpandablePanel title={title} data={data} disabled={isAlwaysExpanded}>
      <div className="flex flex-col w-full">
        <div
          className={classnames("flex", {
            "flex-wrap": financialInfo.length > 3
          })}
        >
          {financialInfo.map((financialInfoItem, index) => (
            <div
              key={index}
              className={classnames(
                "flex border-t border-white-dark",
                financialInfo.length <= 3 && `w-1/${financialInfo.length}`,
                {
                  "w-1/2": financialInfo.length > 3,
                  "w-full": financialInfo.length === 1
                }
              )}
            >
              <FinancialDetails
                isTotal={isTotal}
                maxChart={maxChart}
                {...financialInfoItem}
              />
              {((financialInfo.length > 3 && index % 2 === 0) ||
                (financialInfo.length <= 3 &&
                  index !== financialInfo.length - 1)) && (
                <div className="bg-white-dark w-px" />
              )}
            </div>
          ))}
        </div>
      </div>
    </DataExpandablePanel>
  );
};

export default FinancialOverview;
