import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Body2Bold, Body2Light, Text1Bold, Title2Bold, Title3Bold } from "@metyis-ds/typography";
import Layout from "@components/layouts/Layout";
import "./ImageModal.style.css";
import { Buffer } from "buffer";

interface IUserPromptModalProps {
  show: boolean;
  src: string;
  name: string;
  grans: string[];
  slideIndex: number;
//   content: {
//     title: string;
//     game: string;
//     description: string;
//     no: string;
//     yes: string;
//   };
  onClose: () => void;
}

const ImageModal: React.FC<IUserPromptModalProps> = ({
  show,
  name,
  grans,
  src,
  onClose,
  slideIndex
//   onNo,
//   onYes
}) => {
  const [el, setEl] = useState<Element | undefined>(undefined);

  useEffect(() => {
    if (!el) {
      const el = document.createElement("div");
      setEl(el);

      const modalEl = document.getElementById("modal-root");

      modalEl?.appendChild(el);

      // return () => {
      //   modalEl?.removeChild(el);
      // };
    }
  }, []);

  const slideCode = Buffer.from(slideIndex.toString() + "_" + name).toString("base64")
  const children = (
    <Layout
      division="full"
      className="absolute inset-0 h-screen w-screen z-modal"
    >
      <div className="flex items-center justify-center h-screen">
        <div className="absolute h-full w-full bg-grey-medium opacity-70 inset-0 z-" onClick={onClose}/>
        <div className=" w-auto bg-white-light z-10 rounded-lg">
          <div className="rounded-lg bg-white-light h-full">
            <div className="flex flex-row justify-between items-center px-8 pt-4 w-full">
              <Text1Bold>{name}</Text1Bold>
              <a target="_blank" href={`/slide/${slideCode}`}>Open in new Tab</a>
            </div>
            <img src={src} className="w-[50vw] rounded-lg" />
          </div>
        </div>
      </div>
    </Layout>
  );
  return show && el ? createPortal(children, el) : <></>;
};

export default ImageModal;
