import React from "react";
import { useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import BusinessExplain from "./Explain";
import BusinessOperations from "./Operations";
import stateBusiness from "./state";

const RouteBusiness = () => {
  const { page } = useRecoilValue(stateBusiness);

  return (
    <Switch value={page}>
      <Route routeValue={0} Component={BusinessExplain} />
      <Route routeValue={1} Component={BusinessOperations} />
    </Switch>
  );
};

export default RouteBusiness;
