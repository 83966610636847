import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import FinancialOverview from "./FinancialOverview";
import InventoryOverview from "./InventoryOverview";
import ProductionOverview from "./ProductionOverview";
import stateBusiness, { EPages, pageData } from "./state";
import { authState } from "@state/auth";
import api from "@api";

const Routes = () => {
  const page = useRecoilValue(stateBusiness);
  const [data, setData] = useRecoilState(pageData);
  const auth = useRecoilValue(authState);

  const getData = async () => {
    const { data, status } = await api.postOverview({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      setData({
        production_overview: data.production_overview,
        financial_overview: data.financial_overview,
        inventory_overview: data.inventory_overview,
        preinv: data.preinv,
        preprofit: data.preprofit,
        presales: data.presales,
        profit_overview: data.profit_overview,
        running_profit: data.running_profit,
        running_sales: data.running_sales,
        running_sales_raw: data.running_sales_raw
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.PRODUCTION_OVERVIEW}
        Component={ProductionOverview}
      />
      <Route
        routeValue={EPages.INVENTORY_OVERVIEW}
        Component={InventoryOverview}
      />
      <Route
        routeValue={EPages.FINANCIAL_OVERVIEW}
        Component={FinancialOverview}
      />
    </Switch>
  );
};

export default Routes;
