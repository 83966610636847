import { atom } from "recoil";

export interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
  inventory_overview?: number[][];
  sales?: number[][];
  profit_overview?: number[][];
  running_sales?: number[][][];
  running_sales_raw?: number[][][];
  running_profit?: number[][][];
}
export const pageData = atom<IPageData>({
  key: "18-15-data",
  default: {}
})
