import React from "react";
import classnames from "classnames";
import { DataExpandablePanel } from "@metyis-ds/expandable-panel";
import InventoryDetails, { IProps as IInventoryData } from "./InventoryDetails";

export interface IProps {
  totalStock: number;
  inventoryData: IInventoryData[];
  availableStock: number;
  unitsSold: number[];
  title: string;
  maxChart?: number;
  isTotal?: boolean;
}

const FinancialOverview: React.FC<IProps> = ({
  totalStock,
  inventoryData,
  availableStock,
  unitsSold,
  title,
  maxChart,
  isTotal
}) => {
  let data = [];
  if (isTotal) {
    data.push({ title: "Total production", value: totalStock });
  }
  data = data.concat([
    { title: "Units sold", value: unitsSold[unitsSold.length - 1] },
    { title: "Available stock", value: availableStock }
  ]);

  return (
    <DataExpandablePanel title={title} data={data}>
      <div className="flex flex-col w-full">
        <div
          className={classnames("flex", {
            "flex-wrap": inventoryData.length > 3
          })}
        >
          {inventoryData.map((inventoryDataItem, index) => (
            <div
              key={index}
              className={classnames(
                "flex border-t border-white-dark",
                inventoryData.length <= 3 && `w-1/${inventoryData.length}`,
                {
                  "w-1/2": inventoryData.length > 3,
                  "w-full": inventoryData.length === 1
                }
              )}
            >
              <InventoryDetails
                isTotal={isTotal}
                maxChart={maxChart}
                {...inventoryDataItem}
              />
              {((inventoryData.length > 3 && index % 2 === 0) ||
                (inventoryData.length <= 3 &&
                  index !== inventoryData.length - 1)) && (
                <div className="bg-white-dark w-px" />
              )}
            </div>
          ))}
        </div>
      </div>
    </DataExpandablePanel>
  );
};

export default FinancialOverview;
