export default {
  moveTogether: "You must move on together",
  waitFor: "Please wait for your",
  colleague: "colleague",
  colleagues: "colleagues",
  firstProductionRound: "First production round",
  firstBatchIsOut: "First batch is out",
  timeToDistribute: "Time to distribute",
  title: "Trucks'n'Tricks",
  secondProductionRound: "Second production round",
  preliminarySalesResults: "Preliminary sales results",
  oportunityAdjustments: "An opportunity for adjustments",
  secondBatchOut: "Second batch is Out",
  AllDayResults: "Evening results",
  finalOportunity: "Final opportunity for a markdown",
  endOfDay: "End of day"
};
