import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IInventoryItem } from "../../../route-page/distribution/Distribution";
import Item from "./InventoryItem";
import Truck from "./Truck";

const Container: React.FC<IInventoryItemsProps> = ({
  items: allItems,
  setItems: setAllItems
}) => {
  const maxOverallValue = allItems.reduce(
    (acc, item) => (item.numItems > acc ? item.numItems : acc),
    0
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Truck
          index={0}
          capacity={70}
          spots={allItems.length}
          binName="truck1"
          itemChanged={(item, v) =>
            setAllItems((itt: any) => updateItems(itt, item, true, v))
          }
          items={allItems
            .filter((f: any) => f.inTruck1)
            .map((d: any) => ({
              name: d.name,
              Image: d.image,
              allowedValue: d.numItems - d.truck2,
              maxValue: d.numItems,
              value: d.truck1
            }))}
          maxOverallValue={maxOverallValue}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {allItems
            .filter(i => i.numItems > 0)
            .map((it: any) => (
              <Item
                key={it.name}
                isDropped={(e, b) => {
                  setAllItems((itt: any) => {
                    const s = addItemIfNotPresent(itt, e, b == "truck1");
                    return s;
                  });
                }}
                onClick={() => {
                  // Add item to both trucks
                  setAllItems((itt: any) => {
                    const s = addItemIfNotPresent(itt, it.name, true);
                    const s2 = addItemIfNotPresent(s, it.name, false);
                    return s2;
                  })
                }}
                numItems={it.numItems - it.truck1 - it.truck2}
                name={it.name}
                Image={it.image}
              />
            ))}
        </div>
        <Truck
          index={1}
          capacity={70}
          spots={allItems.length}
          binName="truck2"
          itemChanged={(item, v) =>
            setAllItems((itt: any) => updateItems(itt, item, false, v))
          }
          items={allItems
            .filter((f: any) => f.inTruck2)
            .map((d: any) => ({
              name: d.name,
              Image: d.image,
              allowedValue: d.numItems - d.truck1,
              maxValue: d.numItems,
              value: d.truck2
            }))}
          maxOverallValue={maxOverallValue}
        />
      </div>
    </div>
  );
};
interface IInventoryItemsProps {
  items: IInventoryItem[];
  setItems: (items: IInventoryItem[]) => void;
}

export const InventorySlider: React.FC<IInventoryItemsProps> = props => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container {...props} />
    </DndProvider>
  );
};

const addItemIfNotPresent = (
  items: any,
  itemObject: string,
  isTruck1: boolean
) => {
  const newItems = items.slice();
  const objIndex = items.findIndex((d: any) => d.name == itemObject);
  if (objIndex != -1) {
    if (isTruck1) {
      newItems[objIndex].inTruck1 = true;
    } else {
      newItems[objIndex].inTruck2 = true;
    }
  }
  return newItems;
};

const updateItems = (
  items: IInventoryItem[],
  item: string,
  isTruck1: boolean,
  v: number
) => {
  const newItems = items.slice();
  const objIndex = items.findIndex((d: any) => d.name == item);
  if (objIndex != -1) {
    if (isTruck1) {
      newItems[objIndex].truck1 = v;
    } else {
      newItems[objIndex].truck2 = v;
    }
  }
  return newItems;
};

export default InventorySlider;
