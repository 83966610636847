import { atom } from "recoil";

export enum EPages {
  PRODUCTION_OVERVIEW = 0,
  FINANCIAL_OVERVIEW = 1
}

export const pageState = atom({
  key: "9-30-state",
  default: EPages.PRODUCTION_OVERVIEW
});

interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
}
export const pageData = atom<IPageData>({
  key: "9-30-data",
  default: {}
})

export default pageState;
