import React from "react";
import { IconNames } from "@metyis-ds/icon";
import { Tag } from "@metyis-ds/tag";
import { Body1Bold, Body2Light, Title2Medium } from "@metyis-ds/typography";
import { Value } from "@metyis-ds/value";
import ProductionLine from "./ProductionLine";
import content from "./productionOverview.content";
import { ITEMS } from "../../constants";

export interface IProductionLine {
  icon: IconNames;
  value: number;
  donutValue: number;
}

export interface IProps {
  productionLines: IProductionLine[];
  subtitle: string;
  tag: {
    color: string;
    label: string;
  };
}

const ProductionOverview: React.FC<IProps> = ({
  productionLines,
  subtitle,
  tag
}) => {
  const totalProduction =
    productionLines.length &&
    productionLines.map(pl => pl.value).reduce((acc, curr) => acc + curr);
  return (
    <section className="flex flex-col">
      <Tag backgroundColor={tag.color}>{tag.label}</Tag>
      <Title2Medium>{content.title}</Title2Medium>
      <Body2Light className="my-lg">{subtitle}</Body2Light>
      <div className="flex my-xxxl w-full flex-col border-2 rounded-md">
        <div className="flex border-b-2 w-full py-md">
          <Body1Bold className="px-lg">{content.total}</Body1Bold>
          <Value size="sm" value={totalProduction} />
        </div>
        <div className="grid grid-flow-row grid-cols-list gap-y-lg py-lg">
          {productionLines.map((productionLine, index) => (
            <div className="flex max-w-[210px]" key={index}>
              <ProductionLine name={ITEMS[index].name} {...productionLine} />
              {index !== productionLines.length - 1 && (
                <div className="bg-white-dark w-px"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductionOverview;
