export default {
  welcome: "Welcome!",
  firstParagraph:
    "Today, you will run a food preparation and delivery business for a full day.",
  secondParagraph:
    "You’ll be asked to make plenty of important decisions, from production to distribution. Each manager has only one shot at proving themself!",
  thirdParagraph:
    "Don’t forget that data can play an important role in the decision-making process!",
  fourthParagraph:
    "Please feel free to ask for relevant data. We have plenty of valuable data that may just interest you.",
  fourthParagraphOnline: "Check out the 'See Slides' button on the upper left corner! Make sure to combine the right categories and granularities to obtain relevant data. We have plenty of valuable data that may just interest you.",
  fifthParagraph: "Let the best manager win!",
  ctaText: "Game description",
  sidePanelSubtitle: "I'm the manager and you will lead me!",
  sidePanelTitle: "Hi,",
  title: "Trucks ‘n’ Tricks"
};
