import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import { ESteps, stepState } from "@state/step";
import pageState, { EPages, pageData } from "./state";
import {
  FinancialOverview,
  IFinancialSummary
} from "@templates/financial-overview";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import content from "./FinancialOverview.content";
import { DESTINATIONS, EnumToArray, ITEMS } from "../../constants";
import { IPageData } from "@routes/step-18/state";
import api from "@api";
import { authState } from "@state/auth";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const nullSummary: IFinancialSummary = {
  costs: 0,
  selectedLabel: "12pm",
  financialInfo: [],
  profit: 0,
  sales: 0,
  title: "Total"
};

const getMarkets = (rawData: IPageData) => {
  const markets: IFinancialSummary[] = [];
  if (rawData && rawData.sales) {
    rawData.sales.forEach((d, i) => {
      const totalSales = d.reduce(function (a, b) {
        return a + b;
      });

      if (totalSales > 0) {
        // Market is present
        markets.push({
          title: DESTINATIONS[i].name,
          selectedLabel: "6pm",
          profit: rawData.running_profit
            ? rawData.running_profit[2][i].reduce((a, b) => a + b)
            : 0,
          sales: rawData.sales
            ? rawData.sales[i].reduce(function (a, b) {
                return a + b;
              }) * 10
            : 0,
          costs:
            ((rawData.inventory_overview
              ? rawData.inventory_overview[i].reduce(function (a, b) {
                  return a + b;
                })
              : 0) +
              (rawData.sales
                ? rawData.sales[i].reduce(function (a, b) {
                    return a + b;
                  })
                : 0)) *
            5,
          financialInfo: ITEMS.map((item, index) => {
            return {
              iconName: item.icon,
              title: item.name,
              availableStock: rawData.inventory_overview
                ? rawData.inventory_overview[i][index]
                : 0,
              costs:
                ((rawData.inventory_overview
                  ? rawData.inventory_overview[i][index]
                  : 0) +
                  (rawData.sales ? rawData.sales[i][index] : 0)) *
                5,
              unitsSold: [
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[0][i][index]
                  : 0,
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[1][i][index]
                  : 0,
                rawData.running_sales_raw
                  ? rawData.running_sales_raw[2][i][index]
                  : 0
              ],
              sales: rawData.sales ? rawData.sales[i][index] : 0,
              profit: rawData.running_profit
                ? rawData.running_profit[2][i][index]
                : 0,
              selectedLabel: "6pm"
            };
          }).filter(f => f.costs > 0)
        });
      }
    });
  }
  return markets;
};

const FinancialOverviewPage = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const setPage = useSetRecoilState(pageState);
  const rawFinancial = useRecoilValue(pageData);

  const markets = getMarkets(rawFinancial);

  let financialSummary: IFinancialSummary = nullSummary;
  if (rawFinancial) {
    const costs =
      ((rawFinancial.inventory_overview
        ? rawFinancial.inventory_overview
            .reduce(function (a, b) {
              return a.concat(b);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : 0) +
        (rawFinancial.sales
          ? rawFinancial.sales
              .reduce(function (a, b) {
                return a.concat(b);
              })
              .reduce(function (a, b) {
                return a + b;
              })
          : 0)) *
      5;
    const sales = rawFinancial.sales
      ? rawFinancial.sales
          .reduce(function (a, b) {
            return a.concat(b);
          })
          .reduce(function (a, b) {
            return a + b;
          }) * 10
      : 0;
    financialSummary = {
      ...nullSummary,
      costs,
      sales,
      profit: sales - costs,
      isTotal: true,
      financialInfo: ITEMS.map((item, index) => {
        const costs =
          ((rawFinancial.inventory_overview
            ? rawFinancial.inventory_overview.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0) +
            (rawFinancial.sales
              ? rawFinancial.sales.reduce((total, a) => (total += a[index]), 0)
              : 0)) *
          5;
        const sales = rawFinancial.sales
          ? rawFinancial.sales.reduce((total, a) => (total += a[index]), 0) * 10
          : 0;

        return {
          iconName: item.icon,
          title: item.name,
          costs,
          sales,
          profit: sales - costs,
          unitsSold: [
            rawFinancial.running_profit
              ? rawFinancial.running_profit[0].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawFinancial.running_profit
              ? rawFinancial.running_profit[1].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawFinancial.running_profit
              ? rawFinancial.running_profit[2].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0
          ],
          selectedLabel: "6pm"
        };
      })
    };
  }
  const handleBackClick = useCallback(() => {
    setPage(EPages.INVENTORY_OVERVIEW);
  }, []);

  const handleClick = useCallback(async () => {
    const { status: statusStep } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: ESteps.CHECKPOINT_10
    });
    if (statusStep == 200) {
      setStep(ESteps.CHECKPOINT_10);
    }
  }, []);

  return (
    <Layout>
      <DefaultLeftPanel
        className="bg-sunset-sky-gradient"
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
      />
      <ContentLayout onBackClick={handleBackClick}>
        <FinancialOverview
          maxChart={1200}
          financialSummaryList={[financialSummary, ...markets]}
          tag={{
            color: defaultTheme.colors.blue.medium,
            label: content.tagLabel
          }}
        />
        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.FINANCIAL_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default FinancialOverviewPage;
