import { atom } from "recoil";

export enum EPages {
  DISTRIBUTION_SETUP = 0,
}

const state = atom({
  key: "10am-state",
  default: EPages.DISTRIBUTION_SETUP
});

export default state;
