import styled, { css } from "styled-components";

interface IListNumberProps {}

const ListNumber = styled.ol<IListNumberProps>(
  ({ theme }) => css`
    position: relative;
    list-style-type: upper-alpha;
    counter-reset: li;

    li {
      position: relative;
      min-height: 172px;
      list-style-type: upper-alpha;
      counter-increment: li;
      z-index: 0;
      display: flex;
      border-radius: 16px;
      padding: 24px;
      background-color: ${theme.colors.yellow.light};
      overflow: hidden;

      :before {
        z-index: -1;
        content: counter(li);
        position: absolute;
        top: -3px;
        font-size: 244px;
        line-height: 172px;
        color: #fff;
        opacity: 0.3;
        font-family: "neue-haas-grotesk-display";
        font-weight: bold;
        left: 0;
        width: 134px;
        display: flex;
        justify-content: center;
      }
      :nth-child(3n + 2) {
        background-color: ${theme.colors.blue.light};
      }

      :nth-child(3n + 3) {
        background-color: ${theme.colors.green.light};
      }

      &:not(:first-child) {
        margin-top: ${theme.spaceUnit.lg};
      }
    }
  `
);

export default ListNumber;
