export default {
  congratsTitle: "Congratulations!",
  finalScoreTitle: "This is the final score.",
  goBack: "Back",
  scorePlace: "Place",
  scoreTitle: "Hurray!",
  youNowHave: "",
  scoreType: {
    excellent: "Excellent",
    good: "Good",
    medium: "Medium",
    reasonable: "Reasonable",
  },
  finalScore: "Final Score",
  points: "Points",
  startGameBtn: "Start a new game"
};
