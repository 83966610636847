import React from "react";
import { Text2Roman, Text4Light, Title3Light } from "@metyis-ds/typography";
import content from "./Distribution.content";
import DestinationDrop from "@components/distribution/DestinationDrop";
import { InventorySlider } from "@components/distribution/inventory-slider";
import { IDestination } from "../../constants";

export interface IInventoryItem {
  num: number;
  name: string;
  image: any;
  numItems: number;
  truck1: number;
  truck2: number;
  inTruck1: boolean;
  inTruck2: boolean;
}
interface IDistributionProps {
  items: IInventoryItem[];
  destinations: (IDestination | null)[];
  setItems: (items: IInventoryItem[]) => void;
  setDestinations: (destinations: IDestination[]) => void;
}
const Distribution: React.FC<IDistributionProps> = ({
  items,
  setItems,
  destinations,
  setDestinations
}) => {
  return (
    <div className="mb-xxl">
      <Title3Light className="mb-xxl">{content.title}</Title3Light>
      <div className="mb-xxxl">
        <InventorySlider items={items} setItems={setItems} />
      </div>
      {destinations[0] != null &&
        destinations[1] != null &&
        destinations[0].name == destinations[1].name && (
          <div className="flex justify-center items-center">
            <Text2Roman className="text-red-medium mb-lg">
              You can't have the same destination for both trucks
            </Text2Roman>
          </div>
        )}
      <div>
        <DestinationDrop
          destinations={destinations}
          onDestinationsChange={d => {
            setDestinations(d);
          }}
        />
      </div>
    </div>
  );
};

export default Distribution;
