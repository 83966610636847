import React, { useState } from "react";
import { Icon } from "@metyis-ds/icon";
import { MButton } from "@metyis-ds/button";
import { Title3Light } from "@metyis-ds/typography";
import ContentLayout from "@components/layouts/ContentLayout";
import SidePanel from "@components/side-panel/SidePanel";
import FinancialOverviewTemplate, {
  IProps as IFinancialOverviewTemplateProps
} from "./FinancialOverviewTemplate";
import content from "./financial.content";

type IFinancialOverviewSidePanelProps = IFinancialOverviewTemplateProps;

const FinancialOverviewSidePanel: React.FC<IFinancialOverviewSidePanelProps> =
  props => {
    const [show, setShow] = useState(false);

    const header = (
      <div className="flex items-center justify-end w-full">
        <Icon
          name="close"
          className="cursor-pointer"
          onClick={() => setShow(false)}
        />
      </div>
    );

    return (
      <div>
        <MButton name="arrow-right-simple" onClick={() => setShow(true)}>
          {content.lastResults}
        </MButton>
        {show && (
          <SidePanel>
            <ContentLayout header={header}>
              <FinancialOverviewTemplate {...props} />
            </ContentLayout>
          </SidePanel>
        )}
      </div>
    );
  };

export default FinancialOverviewSidePanel;
