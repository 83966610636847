import React from "react";

interface ISwitchProps {
  value: string | number;
  children: JSX.Element | JSX.Element[];
}

const Switch: React.FC<ISwitchProps> = ({ value, children }) => {
  let newChildren: any;

  if (Array.isArray(children)) {
    newChildren = children.map((component: any) =>
      React.cloneElement(component, { _currentValue: value })
    );
  } else {
    newChildren = React.cloneElement(children, { _currentValue: value });
  }

  return newChildren;
};

export default Switch;
