import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import { ESteps, stepState } from "@state/step";
import pageState, { EPages, IPageData, pageData } from "./state";
import {
  FinancialOverview,
  IFinancialSummary
} from "@templates/financial-overview";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import content from "./FinancialOverview.content";
import { DESTINATIONS, EnumToArray, ITEMS } from "../../constants";
import api from "@api";
import { authState } from "@state/auth";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const nullSummary: IFinancialSummary = {
  costs: 0,
  selectedLabel: "12pm",
  financialInfo: [],
  profit: 0,
  sales: 0,
  title: "Total"
};

const getMarkets = (rawData: IPageData) => {
  const markets: IFinancialSummary[] = [];
  if (rawData && rawData.inventory_overview) {
    rawData.inventory_overview.forEach((d, i) => {
      const total = d.reduce(function (a, b) {
        return a + b;
      });
      if (total > 0) {
        // Market is present
        markets.push({
          title: DESTINATIONS[i].name,
          selectedLabel: "12pm",
          profit: rawData.preprofit
            ? rawData.preprofit[i].reduce((a, b) => a + b)
            : 0,
          sales: rawData.presales
            ? rawData.presales[i].reduce(function (a, b) {
                return a + b;
              }) * 10
            : 0,
          costs: rawData.inventory_overview
            ? rawData.inventory_overview[i].reduce(function (a, b) {
                return a + b;
              }) * 5
            : 0,
          financialInfo: ITEMS.map((item, index) => {
            return {
              iconName: item.icon,
              title: item.name,
              costs: rawData.inventory_overview
                ? rawData.inventory_overview[i][index]
                : 0,
              unitsSold: [
                0,
                rawData.presales ? rawData.presales[i][index] * 10 : 0
              ],
              sales: rawData.presales ? rawData.presales[i][index] : 0,
              profit: rawData.preprofit ? rawData.preprofit[i][index] : 0,
              selectedLabel: "12pm"
            };
          }).filter(f => f.costs > 0)
        });
      }
    });
  }
  return markets;
};

const FinancialOverviewPage = () => {
  const setStep = useSetRecoilState(stepState);
  const auth = useRecoilValue(authState);
  const setPage = useSetRecoilState(pageState);

  const rawFinancial = useRecoilValue(pageData);

  const markets = getMarkets(rawFinancial);

  let financialSummary: IFinancialSummary = nullSummary;
  if (rawFinancial) {
    financialSummary = {
      ...nullSummary,
      isTotal: true,
      profit: (rawFinancial.preprofit || [])
        .reduce(function (a, b) {
          return a.concat(b);
        })
        .reduce(function (a, b) {
          return a + b;
        }),
      costs:
        (rawFinancial.inventory_overview || [])
          .reduce(function (a, b) {
            return a.concat(b);
          })
          .reduce(function (a, b) {
            return a + b;
          }) * 5,
      sales:
        (rawFinancial.presales || [])
          .reduce(function (a, b) {
            return a.concat(b);
          })
          .reduce(function (a, b) {
            return a + b;
          }) * 10,
      financialInfo: ITEMS.map((item, index) => {
        return {
          iconName: item.icon,
          title: item.name,
          costs:
            (rawFinancial.inventory_overview
              ? rawFinancial.inventory_overview.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0) * 5,
          profit: rawFinancial.preprofit
            ? rawFinancial.preprofit.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0,
          unitsSold: [
            rawFinancial.running_profit
              ? rawFinancial.running_profit[0].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawFinancial.running_profit
              ? rawFinancial.running_profit[1].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0
          ],
          sales:
            (rawFinancial.presales
              ? rawFinancial.presales.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0) * 10,
          selectedLabel: "12pm"
        };
      })
    };
  }

  const handleBackClick = useCallback(() => {
    setPage(EPages.INVENTORY_OVERVIEW);
  }, []);

  const handleClick = useCallback(async () => {
    const { status: statusStep } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: ESteps.CHECKPOINT_6
    });
    if (statusStep == 200) {
      setStep(ESteps.CHECKPOINT_6);
    }
  }, []);

  return (
    <Layout>
      <DefaultLeftPanel
        className="bg-blue-sky-gradient"
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
      />
      <ContentLayout onBackClick={handleBackClick}>
        <FinancialOverview
          financialSummaryList={[financialSummary, ...markets]}
          tag={{
            color: defaultTheme.colors.blue.medium,
            label: content.tagLabel
          }}
        />
        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.FINANCIAL_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default FinancialOverviewPage;
