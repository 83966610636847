import { atom } from "recoil";

export enum EPages {
  PRODUCTION_OVERVIEW = 0,
  // INVENTORY_OVERVIEW = 1,
  // FINANCIAL_OVERVIEW = 2
}

const pageState = atom({
  key: "15-state",
  default: EPages.PRODUCTION_OVERVIEW
});

interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
  inventory_overview?: number[][];
  preinv?: number[][];
  preprofit?: number[][];
  presales?: number[][];
  profit_overview?: number[][];
}
export const pageData = atom<IPageData>({
  key: "15-data",
  default: {}
})
export default pageState;
