import { atom } from "recoil";

export enum ESteps {
  LOGIN = -1,
  WELCOME,
  BUSINESS_EXPLAIN,
  CHECKPOINT_1,
  PRODUCTION_1,
  CHECKPOINT_2,
  BATCH_1,
  CHECKPOINT_3,
  DISTRIBUTION_1,
  CHECKPOINT_4,
  PRODUCTION_2,
  CHECKPOINT_5,
  PRELIMINARY_SALES_RESULTS,
  CHECKPOINT_6,
  ADJUSTMENTS_1,
  CHECKPOINT_7,
  BATCH_2,
  CHECKPOINT_8,
  DISTRIBUTION_2,
  CHECKPOINT_9,
  RESULTS,
  CHECKPOINT_10,
  ADJUSTMENTS_2,
  CHECKPOINT_11,
  END_OF_DAY
}

/* Parameters to control Checkpoints screens. Screen needs to know the time and copy of the progress bar component. Add remaining checkpoints once done */
export const TransitionParams: { [key: number]: any } = {
  [ESteps.CHECKPOINT_1]: {
    progress: 10,
    progressBefore: 0,
    text: "06:00",
    textBefore: "06:00",
    inverted: true,
    invertedBefore: true
  },
  [ESteps.CHECKPOINT_2]: {
    progress: 25,
    progressBefore: 10,
    text: "09:30",
    textBefore: "06:00",
    invertedBefore: true
  },
  [ESteps.CHECKPOINT_3]: {
    progress: 35,
    progressBefore: 25,
    text: "10:00",
    textBefore: "09:30",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_4]: {
    progress: 40,
    progressBefore: 35,
    text: "11:00",
    textBefore: "10:00",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_5]: {
    progress: 50,
    progressBefore: 40,
    text: "12:30",
    textBefore: "11:00",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_6]: {
    progress: 60,
    progressBefore: 55,
    text: "13:30",
    textBefore: "12:30",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_7]: {
    progress: 75,
    progressBefore: 60,
    text: "15:00",
    textBefore: "13:30",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_8]: {
    progress: 77,
    progressBefore: 75,
    text: "15:15",
    textBefore: "15:00",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_9]: {
    progress: 85,
    progressBefore: 77,
    text: "18:00",
    textBefore: "15:15",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_10]: {
    progress: 88,
    progressBefore: 85,
    text: "18:15",
    textBefore: "18:00",
    invertedBefore: false
  },
  [ESteps.CHECKPOINT_11]: {
    progress: 100,
    progressBefore: 88,
    text: "19:00",
    textBefore: "18:15",
    inverted: true,
    invertedBefore: false
  }
};
export const stepState = atom<ESteps>({
  key: "stepState",
  default: ESteps.LOGIN
});
