import React, { useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import { pageData } from "./state";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import { ProductionOverviewTemplate } from "@templates/production-overview";
import content from "./ProductionOverview.content";
import { ITEMS } from "../../constants";
import { ESteps, stepState } from "@state/step";
import api from "@api";
import { authState } from "@state/auth";

const ProductionOverviewPage = () => {
  // const setPage = useSetRecoilState(pageState);
  const setStep = useSetRecoilState(stepState);
  const [auth, setAuth] = useRecoilState(authState);

  const productionLinesRaw = useRecoilValue(pageData).production_overview || [];
  const total = productionLinesRaw
    .filter(f => f != 0)
    .reduce((total, a) => (total += a), 0);
  const productionLines = productionLinesRaw.map((d, i) => {
    const icon = ITEMS[i].icon;
    return {
      icon,
      value: d,
      donutValue: +((d / total) * 100).toFixed(0)
    };
  });
  const handleClick = async () => {
    const { status: statusStep } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: ESteps.CHECKPOINT_8
    })
    if(statusStep == 200) {
      setStep(ESteps.CHECKPOINT_8);
    }
  }

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        className="bg-blue-sky-gradient"
      />
      <ContentLayout>
        <ProductionOverviewTemplate
          productionLines={productionLines}
          subtitle={content.subtitle}
          tag={{ color: defaultTheme.colors.blue.medium, label: content.tag }}
        />
        <LButton onClick={handleClick}>{content.ctaText}</LButton>
      </ContentLayout>
    </Layout>
  );
};

export default ProductionOverviewPage;
