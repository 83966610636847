import React, { useEffect, useState } from "react";
import { Tag } from "@metyis-ds/tag";
import { defaultTheme } from "@metyis-ds/tokens";
import { Title3Light } from "@metyis-ds/typography";
import {
  IItem,
  MAX_AVAILABLE_WORKERS,
  MAX_WORKERS_PER_LINE
} from "../../constants";
import ListNumber from "@components/list/ListNumber";
import ProductionLine from "@components/production/ProductionLine";
import styled from "styled-components";
interface IProps {
  tag?: string;
  title: string;
  handleProdLinesChange: (productionLines: {
    [key: number]: { item: IItem; numWorkers: number };
  }) => void;
  handleWorkersChange: (availableWorkers: number) => void;

  prefilledProductionLines?: {
    [key: number]: { item: IItem; numWorkers: number };
  };
}

const ProductionSetupTemplate: React.FC<IProps> = ({
  tag,
  title,
  handleProdLinesChange,
  handleWorkersChange,
  prefilledProductionLines
}) => {
  const [productionLines, setProductionLines] = useState<{
    [key: number]: { item: IItem; numWorkers: number };
  }>(prefilledProductionLines || {});

  const availableWorkers =
    MAX_AVAILABLE_WORKERS -
    (productionLines[1]?.numWorkers ?? 0) -
    (productionLines[2]?.numWorkers ?? 0) -
    (productionLines[3]?.numWorkers ?? 0);
  const maxNumWorkers1 = Math.min(
    availableWorkers + (productionLines[1]?.numWorkers ?? 0),
    MAX_WORKERS_PER_LINE
  );
  const maxNumWorkers2 = Math.min(
    availableWorkers + (productionLines[2]?.numWorkers ?? 0),
    MAX_WORKERS_PER_LINE
  );
  const maxNumWorkers3 = Math.min(
    availableWorkers + (productionLines[3]?.numWorkers ?? 0),
    MAX_WORKERS_PER_LINE
  );

  useEffect(() => {
    handleProdLinesChange(productionLines);
  }, [productionLines]);

  useEffect(() => {
    handleWorkersChange(availableWorkers);
  }, [availableWorkers]);

  const handleItemChange = (num: number, item: IItem) => {
    setProductionLines(productionLines => ({
      ...productionLines,
      [num]: { item, numWorkers: productionLines[num]?.numWorkers ?? 0 }
    }));
  };

  const handleNumWorkersChange = (num: number, numWorkers: number) => {
    setProductionLines(productionLines => ({
      ...productionLines,
      [num]: { item: productionLines[num]?.item ?? "", numWorkers }
    }));
  };

  const StyledDiv = styled.div`
    animation: anim 5s infinite;
    @keyframes anim {
      0% {
        transform: scale(1, 1);
      }
      15% {
        transform: translate(2%, 2%) scale(1.05, 1.05);
      }
      20% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  `;

  return (
    <div className="mb-xxl">
      <Title3Light className={tag ? "mb-md" : "mb-xxl"}>{title}</Title3Light>
      {tag && (
        <StyledDiv className="mb-md">
          <Tag
            color={defaultTheme.colors.white.light}
            backgroundColor={defaultTheme.colors.grey.dark}
          >
            <span className="mr-xl">{tag}</span>
          </Tag>
        </StyledDiv>
      )}
      <ListNumber>
        <ProductionLine
          maxNumWorkers={maxNumWorkers1}
          num={1}
          onItemChange={handleItemChange}
          onNumWorkersChange={handleNumWorkersChange}
          defaultItem={
            prefilledProductionLines && prefilledProductionLines[1].item
          }
          defaultWorkers={
            prefilledProductionLines && prefilledProductionLines[1].numWorkers
          }
        />
        <ProductionLine
          maxNumWorkers={maxNumWorkers2}
          num={2}
          onItemChange={handleItemChange}
          onNumWorkersChange={handleNumWorkersChange}
          defaultItem={
            prefilledProductionLines && prefilledProductionLines[2].item
          }
          defaultWorkers={
            prefilledProductionLines && prefilledProductionLines[2].numWorkers
          }
        />
        <ProductionLine
          maxNumWorkers={maxNumWorkers3}
          num={3}
          onItemChange={handleItemChange}
          onNumWorkersChange={handleNumWorkersChange}
          defaultItem={
            prefilledProductionLines && prefilledProductionLines[3].item
          }
          defaultWorkers={
            prefilledProductionLines && prefilledProductionLines[3].numWorkers
          }
        />
      </ListNumber>
    </div>
  );
};

export default ProductionSetupTemplate;
