export default {
  adjustment: "Adjustment",
  distribution: "Distribution",
  distributionInfo: (numItems: number) =>
    `You filled the trucks with a total of ${numItems} items.`,
  first: "First",
  items: "items",
  locations: "Locations",
  locationsInfo: (profit: number, pctSold: number) =>
    `You sold ${(pctSold*100).toFixed(0)}% of your production. You had a total profit of ${profit}€.`,
  production: "production",
  productionLines: "Production Lines",
  productionLinesInfo: (numItems: number, investment: number) =>
    `You produced ${numItems} items. Your total investment was ${investment}€.`,
  second: "Second",
  workers: "workers",
  title: "Your actions"
};
