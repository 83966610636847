import { atom } from "recoil";

export enum EPages {
  INVENTORY_OVERVIEW = 0,
  FINANCIAL_OVERVIEW = 1
}

const pageState = atom({
  key: "18-30-state",
  default: EPages.INVENTORY_OVERVIEW
});


export interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
  inventory_overview?: number[][];
  sales?: number[][];
  profit_overview?: number[][];
  running_sales?: number[][][];
  running_sales_raw?: number[][][];
  running_profit?: number[][][];
}
export const pageData = atom<IPageData>({
  key: "18-data",
  default: {}
})
export default pageState;
