import React from "react";
import styled from "styled-components";
import { Donut } from "@metyis-ds/donut";
import { Icon, IconNames } from "@metyis-ds/icon";
import { defaultTheme } from "@metyis-ds/tokens";
import { Text2Bold, Text2Roman } from "@metyis-ds/typography";
import { Value } from "@metyis-ds/value";
import content from "../../components/production/production.content";

interface IProps {
  icon: IconNames;
  name: string;
  value: number;
  donutValue: number;
}

const DonutWrapper = styled.div`
  height: 28px;
  width: 28px;
`;

const ProductionLine: React.FC<IProps> = ({
  icon,
  name,
  value,
  donutValue
}) => {
  return (
    <div className="flex flex-col flex-1 px-xl">
      <Text2Roman>{name}</Text2Roman>
      <div className="flex mt-xxl mb-xl items-center justify-between">
        <Value size="sm" value={value} />
        <Icon name={icon} />
      </div>
      <div className="flex items-center">
        {/* <DonutWrapper>
          <Donut
            defaultDonutColor={defaultTheme.colors.white.medium}
            donutThickness={4}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            values={[
              {
                label: `Donut ${name}`,
                usage: Math.abs(donutValue),
                donutColor:
                  donutValue < 0
                    ? defaultTheme.colors.red.medium
                    : defaultTheme.colors.green.medium
              }
            ]}
          />
        </DonutWrapper>
        <Text2Bold
          className={`ml-xs mr-xxs ${
            value < 0 ? "text-red-medium" : "text-green-medium"
          }`}
        >
          {donutValue}%
        </Text2Bold>
        <Text2Roman>{content.inventory}</Text2Roman> */}
      </div>
    </div>
  );
};

export default ProductionLine;
