import React, { useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import styled, { useTheme } from "styled-components";
import Truck1 from "../../../assets/illustrations/distribution/Truck1.svg";
import Truck2 from "../../../assets/illustrations/distribution/Truck2.svg";
import TruckHead1 from "../../../assets/illustrations/distribution/TruckHead1.svg";
import TruckHead2 from "../../../assets/illustrations/distribution/TruckHead2.svg";
import TruckHeadFull1 from "../../../assets/illustrations/distribution/TruckHeadFull1.svg";
import TruckHeadFull2 from "../../../assets/illustrations/distribution/TruckHeadFull2.svg";
import Slider from "./Slider";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCapacity = styled.div`
  color: ${props => props.color};
  font-size: 16px;
  margin-bottom: 12px;
`;

const StyledBorder = styled.div<{ isFull: boolean }>`
  border-radius: 30px;
  border: 2px ${props => (props.isFull ? "solid" : "dashed")}
    ${props => props.color};
  padding: 4px;
  margin: 0px 24px 6px 24px;
  background-color: ${props => (props.isFull ? props.color : "transparent")};
`;

const StyledTruck = styled.div`
  height: 320px;
  width: 290px;
  background-color: ${props => props.color};
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 36px 0px;
  box-sizing: border-box;
`;

const StyledIndex = styled.div`
  opacity: 0.4;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

const StyledMessage = styled.span`
  color: ${props => props.color};
  font-weight: 700;
  font-size: 18px;
  z-index: 1;
  align-self: center;
`;

const StyledItemsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  align-items: flex-end;
  z-index: 1;
  width: 100%;
`;

const StyledPlaceholderContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 65px;
  left: 0px;
  right: 0px;
  justify-content: space-evenly;
`;

const StyledPlaceholder = styled.div<{ hide: boolean }>`
  width: 48px;
  height: 48px;
  border: 2px dashed ${props => props.color};
  border-radius: 12px;
  visibility: ${props => (props.hide ? "hidden" : "visible")};
`;

interface ITruckProps {
  index: number;
  capacity: number;
  spots: number;
  items: {
    name: string;
    Image: any;
    allowedValue: number;
    maxValue: number;
    value: number;
  }[];
  binName: string;
  itemChanged: (item: string, v: number) => void;
  maxOverallValue: number;
}

const Truck: React.FC<ITruckProps> = ({
  items,
  capacity,
  spots,
  binName,
  itemChanged,
  index,
  maxOverallValue
}) => {
  const [itemsOrder, setItemsOrder] = useState<string[]>([]);

  useEffect(() => {
    const newOrder = [...itemsOrder];
    items.forEach(item => {
      if (!newOrder.includes(item.name)) {
        newOrder.push(item.name);
      }
    });

    setItemsOrder(newOrder.filter(name => items.find(v => v.name === name)));
  }, [items]);

  const sortedItems = useMemo(
    () =>
      [...items].sort((a, b) =>
        itemsOrder.indexOf(a.name) < itemsOrder.indexOf(b.name) ? -1 : 1
      ),
    [items, itemsOrder]
  );

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "CARD",
    drop: () => ({ name: binName }),
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));
  const isActive = canDrop && isOver;

  const usedCapacity = useMemo(
    () => items.reduce((acc, item) => acc + item.value, 0),
    [capacity, items]
  );

  const isFull = usedCapacity === capacity;

  const theme = useTheme();
  const colors = index ? theme.colors.green : theme.colors.yellow;
  return (
    <StyledContainer>
      <StyledCapacity color={colors.dark}>
        Truck load: {usedCapacity}/{capacity} items
      </StyledCapacity>
      <StyledBorder
        ref={drop}
        role={"Dustbin"}
        color={isFull ? colors.medium : colors.light}
        isFull={isFull}
      >
        <StyledTruck color={isFull ? colors.medium : colors.light}>
          {items.length == 0 && (
            <StyledIndex>{index ? <Truck2 /> : <Truck1 />}</StyledIndex>
          )}
          {items.length == 0 && (
            <StyledMessage color={colors.medium}>
              Drop the items here!
            </StyledMessage>
          )}
          <StyledPlaceholderContainer>
            {[...Array(spots)].map((v, i) => (
              <StyledPlaceholder
                key={i}
                color={colors.medium}
                hide={i < items.length}
              />
            ))}
          </StyledPlaceholderContainer>
          {sortedItems.length > 0 && (
            <StyledItemsContainer>
              {sortedItems.map(d => {
                return (
                  <Slider
                    onChange={itemChanged}
                    name={d.name}
                    Image={d.Image}
                    key={d.name}
                    allowedValue={Math.min(
                      d.allowedValue,
                      capacity - usedCapacity + d.value
                    )}
                    maxValue={d.maxValue}
                    maxOverallValue={maxOverallValue}
                    color={isFull ? colors.dark : colors.medium}
                    darkColor={isFull ? theme.colors.white.dark : colors.dark}
                  />
                );
              })}
              {[...Array(spots - items.length)].map((v, i) => (
                <div key={i} style={{ width: 48 }} />
              ))}
            </StyledItemsContainer>
          )}
        </StyledTruck>
      </StyledBorder>
      {isFull ? (
        index ? (
          <TruckHeadFull2 />
        ) : (
          <TruckHeadFull1 />
        )
      ) : index ? (
        <TruckHead2 />
      ) : (
        <TruckHead1 />
      )}
    </StyledContainer>
  );
};

export default Truck;
