import { atom } from "recoil";

export enum EPages {
  PRODUCTION_OVERVIEW = 0,
  INVENTORY_OVERVIEW = 1,
  FINANCIAL_OVERVIEW = 2
}

const pageState = atom({
  key: "12-30-state",
  default: EPages.PRODUCTION_OVERVIEW
});

export interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
  inventory_overview?: number[][];
  preinv?: number[][];
  preprofit?: number[][];
  presales?: number[][];
  profit_overview?: number[][];
  running_sales?: number[][][];
  running_profit?: number[][][];
}
export const pageData = atom<IPageData>({
  key: "12-30-data",
  default: {}
})


export default pageState;
