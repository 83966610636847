import React, { useMemo } from "react";

interface IRouteProps {
  _currentValue?: string;
  routeValue: string | number;
  Component: any;
}

const Route: React.FC<IRouteProps> = ({
  _currentValue,
  routeValue,
  Component
}) => {
  return useMemo(
    () => (_currentValue === routeValue ? <Component /> : <></>),
    [_currentValue]
  );
};

export default Route;
