import { atom } from "recoil";

// TODO ADD OTHER PAGES
export enum EPages {
  INVENTORY_OVERVIEW,
  FINANCIAL_OVERVIEW,
  ACTIONS,
  FINAL_SCORE
}
const pageState = atom({
  key: "19-state",
  default: EPages.INVENTORY_OVERVIEW
});


export interface IPageData {
  production_overview?: number[];
  financial_overview?: number[];
  inventory_overview?: number[][];
  sales?: number[][];
  profit_overview?: number[][];
  running_sales?: number[][][];
  running_sales_raw?: number[][][];
  running_profit?: number[][][];
  scores?: any;
  actions?: any;
  
  sales_normal?: number[][]
  sales_discount?: number[][]
  sales_cost?: number[][]
  markdown?: number[]
}
export const pageData = atom<IPageData>({
  key: "19-data",
  default: {}
})

export default pageState;
