export default {
  title: "Get to know your business",
  firstParagraph:
    "Your business is simple. You produce and sell food in different markets.",
  secondParagraph:
    "You prepare each box of food for €5 and sell for €10.",
  thirdParagraph:
    "Any products left at the end of the day are sold at €5 each.",
  ctaText: "Start the game",
  btnOperations: "Operations",
  sidePanelTitle: "These are the main business rules.",
  sidePanelSubtitle: ""
};
