import styled, { css } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const SyledBullet = styled.div<{ selected?: boolean }>(
  ({ selected, theme }) => css`
    border: 1px solid ${theme.colors.blue.medium};
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    width: 12px;

    ${selected &&
    css`
      background-color: ${theme.colors.blue.medium};
    `}

    &:not(:last-child) {
      margin-right: ${theme.spaceUnit.sm};
    }
  `
);
