import { atom } from "recoil";

const stateBusiness = atom({
  key: "business-state",
  default: {
    page: 0
  }
});

export default stateBusiness;
