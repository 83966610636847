import React, { useState } from "react";
import { XLButton } from "@metyis-ds/button";
import { ErrorTextInput } from "@metyis-ds/textinput";
import Layout from "@components/layouts/Layout";
import PngMetyis from "@public/metyis.png";
import IllusCloud1 from "@illustrations/login/cloud1.svg";
import IllusCloud2 from "@illustrations/login/cloud2.svg";
import IllusCity from "@illustrations/login/city.svg";
import IllusTruck from "@illustrations/login/truck.svg";
import { useSetRecoilState } from "recoil";
import { ESteps, stepState } from "@state/step";
import api from "@api";
import { Buffer } from "buffer";
import { Title2Bold } from "@metyis-ds/typography";
import content from "./Login.content";
import { authState } from "@state/auth";

type TypeError = "name" | "game" | "code";

const Login = () => {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] =
    useState<{
      target: TypeError;
      message: string;
    } | null>();
  const setStep = useSetRecoilState(stepState);
  const setAuth = useSetRecoilState(authState);

  const handleCta = async () => {
    if (!(name || room || password)) return;

    try {
      setIsLoading(true);
      setError(null);

      const { data, status } = await api.postJoinGame({
        gameid: room.toUpperCase(),
        userid: name,
        code: Buffer.from(password).toString("base64")
      });
      if (status === 200) {
        setStep(data.currentStep as ESteps);
        setAuth({ gameid: room.toUpperCase(), userid: name });
      }
    } catch ({ error, status }) {
      let target: TypeError = "code";

      switch (status) {
        case 400:
          target = "code";
          break;
        case 401:
          target = "name";
          break;
        case 501:
        case 502:
          target = "game";
          break;
        default:
          break;
      }
      setError({ target, message: error.error });
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  return (
    <Layout division="half">
      <div className="relative bg-sky-gradient overflow-hidden ">
        {/* <img src={PngMetyis} className="absolute top-10 left-10" /> */}
        <IllusCity className="absolute w-auto bottom-0 right-0 h-3/5" />
        <IllusTruck className="absolute bottom-0 left-0" />
        <IllusCloud1 className="absolute w-auto h-auto top-20 right-8" />
        <IllusCloud2 className="absolute w-auto h-auto  top-60 left-12" />
      </div>
      <form
        action="javascript:void(0);"
        className="flex flex-col items-center justify-center"
        onSubmit={handleCta}
      >
        <div className="flex flex-1 flex-col items-center justify-center gap-md w-2/3">
          <Title2Bold className="mb-xxl">{content.title}</Title2Bold>
          <ErrorTextInput
            className="w-full"
            value={name}
            label={content.name}
            placeholder={content.nameDesc}
            onChange={e => setName(e.currentTarget.value)}
            error={error && error.target === "name" ? error.message : undefined}
            required
          />
          <div className="flex gap-md w-full">
            <ErrorTextInput
              className="w-full"
              value={room}
              label={content.room}
              placeholder={content.roomDesc}
              onChange={e => setRoom(e.currentTarget.value)}
              required
              error={
                error && error.target === "game" ? error.message : undefined
              }
            />
            <ErrorTextInput
              className="w-full"
              value={password}
              label={content.password}
              placeholder={content.passwordDesc}
              onChange={e => setPassword(e.currentTarget.value)}
              error={
                error && error.target === "code" ? error.message : undefined
              }
              required
            />
          </div>
        </div>
        <XLButton
          disabled={!(name || room || password) || isLoading}
          type="submit"
          className="h-auto"
        >
          {isLoading ? content.joining : content.cta}
        </XLButton>
      </form>
    </Layout>
  );
};

export default Login;
