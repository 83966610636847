import React from "react";
import { useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import ProductionSetup from "./ProductionSetup";
import OperationsOverview from "./OperationsOverview";
import stateBusiness, { EPages, pageData } from "./state";
import api from "@api";
import { authState } from "@state/auth";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

const Routes = () => {
  const page = useRecoilValue(stateBusiness);
  const auth = useRecoilValue(authState);
  const setPageData = useSetRecoilState(pageData);
  const getCurrentWorkingLines = async () => {
    const { data, status } = await api.postGetProductionBatch2({
      gameid: auth.gameid,
      userid: auth.userid
    });
    if (status == 200) {
      setPageData({
        lines: data.lines || [],
        workers: data.workers || []
      });
    }
  };

  useEffect(() => {
    getCurrentWorkingLines();
  }, []);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.OPERATIONS_OVERVIEW}
        Component={OperationsOverview}
      />
      <Route routeValue={EPages.PRODUCTION_SETUP} Component={ProductionSetup} />
    </Switch>
  );
};

export default Routes;
