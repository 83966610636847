import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { Icon } from "@metyis-ds/icon";
import { defaultTheme } from "@metyis-ds/tokens";
import { SyledBullet } from "./Carousel.style";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface IProps {
  children: JSX.Element[];
  className?: string;
  itemLabels: string[];
}

const Carousel: React.FC<IProps> = ({ children, className, itemLabels }) => {
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(0);

  const handlePrev = useCallback(() => {
    setSelectedCarouselItem(prevItem =>
      prevItem > 0 ? prevItem - 1 : prevItem
    );
  }, []);

  const handleNext = useCallback(() => {
    setSelectedCarouselItem(prevItem =>
      prevItem + 1 < children.length ? prevItem + 1 : prevItem
    );
  }, [children]);

  return (
    <div className={classNames("flex flex-col h-5/6", className)}>
      <ReactCarousel
        centerMode
        centerSlidePercentage={100}
        selectedItem={selectedCarouselItem}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
      >
        {children}
      </ReactCarousel>
      <div className="flex justify-center w-full mb-xl mt-sm">
        {children.map((val, index) => (
          <SyledBullet
            key={index}
            selected={selectedCarouselItem === index}
            onClick={() => setSelectedCarouselItem(index)}
          />
        ))}
      </div>
      <div className="flex justify-center w-full">
        <Icon
          className="cursor-pointer"
          name="arrow-left"
          fill={defaultTheme.colors.blue.medium}
          onClick={handlePrev}
        />
        <span className="w-1/2 text-center mx-md">
          {itemLabels[selectedCarouselItem]}
        </span>
        <Icon
          className="cursor-pointer"
          name="arrow-right"
          fill={defaultTheme.colors.blue.medium}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Carousel;
