import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import pageState, { EPages, pageData } from "./state";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import {
  InventoryOverview,
  IInventorySummary
} from "@templates/inventory-overview";
import content from "./InventoryOverview.content";
import { DESTINATIONS, EnumToArray, ITEMS } from "../../constants";
import { IPageData } from "@routes/step-18/state";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const nullSummary: IInventorySummary = {
  availableStock: 0,
  inventoryData: [],
  title: "Total",
  totalStock: 0,
  unitsSold: [0]
};

const getMarkets = (rawData: IPageData) => {
  const markets: IInventorySummary[] = [];
  if (rawData && rawData.inventory_overview) {
    rawData.inventory_overview.forEach((d, i) => {
      const total =
        d.reduce(function (a, b) {
          return a + b;
        }) + (rawData.sales ? rawData.sales[i].reduce((a, b) => a + b) : 0);

      if (total > 0) {
        // Market is present
        markets.push({
          title: DESTINATIONS[i].name,
          availableStock: rawData.inventory_overview
            ? rawData.inventory_overview[i].reduce(
                (total, a) => (total += a),
                0
              )
            : 0,
          totalStock: total,
          unitsSold: [
            rawData.sales
              ? rawData.sales[i].reduce((total, a) => (total += a), 0)
              : 0
          ],
          inventoryData: ITEMS.map((item, index) => {
            const totalOfItem =
              (rawData.inventory_overview
                ? rawData.inventory_overview[i][index]
                : 0) + (rawData.sales ? rawData.sales[i][index] : 0);
            const salesOfItem = rawData.sales ? rawData.sales[i][index] : 0;
            return {
              iconName: item.icon,
              title: item.name,
              additionalStock: 0,
              initialStock: totalOfItem,
              unitsSold: [
                rawData.running_sales ? rawData.running_sales[0][i][index] : 0,
                rawData.running_sales ? rawData.running_sales[1][i][index] : 0,
                rawData.running_sales ? rawData.running_sales[2][i][index] : 0,
                rawData.running_sales ? rawData.running_sales[3][i][index] : 0
              ],
              availableStock: totalOfItem - salesOfItem,
              selectedLabel: "7pm"
            };
          }).filter(f => f.initialStock > 0)
        });
      }
    });
  }
  return markets;
};

const InventoryOverviewPage = () => {
  const setPage = useSetRecoilState(pageState);
  const rawInventory = useRecoilValue(pageData);

  const markets = getMarkets(rawInventory);

  let inventorySummary: IInventorySummary = nullSummary;
  if (rawInventory) {
    inventorySummary = {
      ...nullSummary,
      isTotal: true,
      totalStock:
        (rawInventory.inventory_overview
          ? rawInventory.inventory_overview
              .reduce(function (a, b) {
                return a.concat(b);
              })
              .reduce(function (a, b) {
                return a + b;
              })
          : 0) +
        (rawInventory.sales
          ? rawInventory.sales
              .reduce(function (a, b) {
                return a.concat(b);
              })
              .reduce(function (a, b) {
                return a + b;
              })
          : 0),
      unitsSold: [
        rawInventory.sales
          ? rawInventory.sales
              .reduce(function (a, b) {
                return a.concat(b);
              })
              .reduce(function (a, b) {
                return a + b;
              })
          : 0
      ],
      availableStock: rawInventory.inventory_overview
        ? rawInventory.inventory_overview
            .reduce(function (a, b) {
              return a.concat(b);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : 0,
      inventoryData: ITEMS.map((item, index) => {
        return {
          iconName: item.icon,
          salesAtNormalPrice:
            (rawInventory.sales_normal
              ? rawInventory.sales_normal.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0) +
            (rawInventory.sales_discount &&
            rawInventory.markdown &&
            rawInventory.markdown[index] == 0
              ? rawInventory.sales_discount
                ? rawInventory.sales_discount.reduce(
                    (total, a) => (total += a[index]),
                    0
                  )
                : 0
              : 0),
          salesAtCostPrice: rawInventory.sales_cost
            ? rawInventory.sales_cost.reduce(
                (total, a) => (total += a[index]),
                0
              )
            : 0,
          salesAtDiscountPrice:
            rawInventory.markdown &&
            rawInventory.markdown[index] != 0 &&
            rawInventory.sales_discount
              ? rawInventory.sales_discount.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : null,
          discountPrice: rawInventory.markdown
            ? rawInventory.markdown[index]
            : null,
          title: item.name,
          totalStock:
            (rawInventory.inventory_overview
              ? rawInventory.inventory_overview.reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0) +
            (rawInventory.sales
              ? rawInventory.sales.reduce((total, a) => (total += a[index]), 0)
              : 0),
          unitsSold: [
            rawInventory.running_sales
              ? rawInventory.running_sales[0].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawInventory.running_sales
              ? rawInventory.running_sales[1].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawInventory.running_sales
              ? rawInventory.running_sales[2].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0,
            rawInventory.running_sales
              ? rawInventory.running_sales[3].reduce(
                  (total, a) => (total += a[index]),
                  0
                )
              : 0
          ],
          selectedLabel: "7pm"
        };
      })
    };
  }
  const handleClick = useCallback(() => {
    setPage(EPages.FINANCIAL_OVERVIEW);
  }, []);

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        textColor="text-white-light"
        className="bg-dark-sky-gradient"
      />
      <ContentLayout>
        <InventoryOverview
          maxChart={100}
          inventorySummaryList={[inventorySummary, ...markets]}
          tag={{
            color: defaultTheme.colors.blue.medium,
            label: content.tagLabel
          }}
        />

        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          disabled={!(rawInventory && Object.keys(rawInventory).length > 0)}
          text={
            rawInventory && Object.keys(rawInventory).length > 0
              ? content.ctaText
              : "Loading Results..."
          }
          onClick={handleClick}
          page={EPages.INVENTORY_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default InventoryOverviewPage;
