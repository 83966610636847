import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { defaultTheme } from "@metyis-ds/tokens";
import pageState, { EPages, pageData } from "./state";
import ContentLayout from "@components/layouts/ContentLayout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import Layout from "@components/layouts/Layout";
import { ProductionOverviewTemplate } from "@templates/production-overview";
import content from "./ProductionOverview.content";
import { EnumToArray, ITEMS } from "../../constants";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const ProductionOverviewPage = () => {
  const setPage = useSetRecoilState(pageState);
  const productionLinesRaw = useRecoilValue(pageData).production_overview || [];

  const total = productionLinesRaw
    .filter(f => f != 0)
    .reduce((total, a) => (total += a), 0);
  const productionLines = productionLinesRaw.map((d, i) => {
    const icon = ITEMS[i].icon;
    return {
      icon,
      value: d,
      donutValue: +((d / total) * 100).toFixed(0)
    };
  });
  const handleClick = useCallback(() => {
    setPage(EPages.INVENTORY_OVERVIEW);
  }, []);

  return (
    <Layout>
      <DefaultLeftPanel
        className="bg-blue-sky-gradient"
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
      />
      <ContentLayout hideHeader>
        <ProductionOverviewTemplate
          productionLines={productionLines}
          subtitle={content.subtitle}
          tag={{ color: defaultTheme.colors.blue.medium, label: content.tag }}
        />
        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.PRODUCTION_OVERVIEW}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default ProductionOverviewPage;
