import React, { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LButton } from "@metyis-ds/button";
import { TabRadio } from "@metyis-ds/radio";
import { ESteps, stepState } from "@state/step";
import pageState, { EPages, pageData } from "./state";
import ContentLayout from "@components/layouts/ContentLayout";
import Layout from "@components/layouts/Layout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import { ActionsTemplate } from "@templates/actions";
import content from "./ActionsOverview.content";
import { ITEMS, DESTINATIONS, EnumToArray } from "../../constants";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";

const mockDataDetails = [
  {
    time: "05:00",
    title: "First production",
    data: [
      {
        item: "ice-cream",
        label: "00 workers",
        value: 0
      },
      {
        item: "cheese",
        label: "00 workers",
        value: 0
      },
      {
        item: "sushi",
        label: "00 workers",
        value: 0
      }
    ]
  },
  {
    time: "11:00",
    title: "Second production",
    data: [
      {
        item: "ice-cream",
        label: "00 workers",
        value: 0
      },
      {
        item: "cheese",
        label: "00 workers",
        value: 0
      },
      {
        item: "sushi",
        label: "00 workers",
        value: 0
      }
    ]
  },
  {
    time: "13:30",
    title: "Adjustment production",
    data: [
      {
        item: "ice-cream",
        label: "04 workers",
        value: 0
      },
      {
        item: "cheese",
        label: "03 workers",
        value: 0
      },
      {
        item: "sushi",
        label: "03 workers",
        value: 0
      }
    ]
  }
];

const mockData = {
  distribution: {
    data: mockDataDetails,
    numItems: 600
  },
  location: {
    data: mockDataDetails,
    financialInfo: 2300,
    soldItems: 0,
    producedItems: 0
  },
  production: {
    data: mockDataDetails,
    financialInfo: 1000,
    numItems: 600
  }
};

const ProductionOverviewPage = () => {
  const setStep = useSetRecoilState(stepState);
  const setPage = useSetRecoilState(pageState);
  const [selectedTab, setSeletedTab] = useState<string>();
  const actionsRaw = useRecoilValue(pageData).actions;
  const inventory = useRecoilValue(pageData).inventory_overview;
  const sales = useRecoilValue(pageData).sales;
  const profit = useRecoilValue(pageData).profit_overview;
  let actions = mockData;

  const locationsMap: {
    first: {
      [key: string]: number[];
    };
    second: {
      [key: string]: number[];
    };
  } = {
    first: {},
    second: {}
  };
  actionsRaw.first_distribution_market.forEach(
    (d: number, locIndex: number) => {
      const loc = DESTINATIONS[d].name;
      const quantityTruck: number[] =
        actionsRaw["first_distribution_truck" + (locIndex + 1)];

      if (locationsMap["first"][loc]) {
        locationsMap["first"][loc] = locationsMap["first"][loc].map(
          (num: number, idx: number) => num + quantityTruck[idx]
        );
      } else {
        locationsMap["first"][loc] = quantityTruck;
      }
    }
  );

  actionsRaw.second_distribution_market.forEach(
    (d: number, locIndex: number) => {
      const loc = DESTINATIONS[d].name;
      const quantityTruck: number[] =
        actionsRaw["second_distribution_truck" + (locIndex + 1)];

      if (locationsMap["second"][loc]) {
        locationsMap["second"][loc] = locationsMap["second"][loc].map(
          (num: number, idx: number) => num + quantityTruck[idx]
        );
      } else {
        locationsMap["second"][loc] = quantityTruck;
      }
    }
  );

  const locationData = Object.keys(locationsMap["first"])
    .map(key => {
      locationsMap["first"][key];
      return {
        time: "10:00",
        title: key + " - First distribution",
        data: locationsMap["first"][key]
          .map((d: number, i: number) => ({
            item: ITEMS[i].icon,
            value: d,
            label: d + " items"
          }))
          .filter(f => f.value > 0)
      };
    })
    .concat(
      Object.keys(locationsMap["second"]).map(key => {
        locationsMap["second"][key];
        return {
          time: "15:00",
          title: key + " - Second distribution",
          data: locationsMap["second"][key]
            .map((d: number, i: number) => ({
              item: ITEMS[i].icon,
              value: d,
              label: d + " items"
            }))
            .filter(f => f.value > 0)
        };
      })
    );

  if (actionsRaw) {
    actions = {
      ...mockData,
      location: {
        data: locationData,
        producedItems:
          sales && inventory
            ? sales?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b) +
              inventory?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b)
            : 0,
        soldItems:
          sales && inventory
            ? sales?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b)
            : 0,
        financialInfo: profit
          ? profit.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b)
          : 0
      },
      production: {
        numItems:
          sales && inventory
            ? sales?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b) +
              inventory?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b)
            : 0,
        financialInfo:
          sales && inventory
            ? (sales?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b) +
                inventory
                  ?.reduce((a, b) => a.concat(b))
                  .reduce((a, b) => a + b)) *
              5
            : 0,
        data: [
          {
            time: "05:00",
            title: "First production",
            data: actionsRaw.first_production_items.map(
              (d: number, i: number) => ({
                item: d >= 0 ? ITEMS[d].icon : "",
                label:
                  "0" + actionsRaw.first_production_workers[i] + " Workers",
                value: actionsRaw.first_production_workers[i]
              })
            )
          },
          {
            time: "11:00",
            title: "Second production",
            data: actionsRaw.second_production_items.map(
              (d: number, i: number) => ({
                item: d >= 0 ? ITEMS[d].icon : "",
                label:
                  "0" + actionsRaw.second_production_workers[i] + " Workers",
                value: actionsRaw.second_production_workers[i]
              })
            )
          },
          {
            time: "13:30",
            title: "Adjustment production",
            data: actionsRaw.adjustment_production_items.map(
              (d: number, i: number) => ({
                item: d >= 0 ? ITEMS[d].icon : "",
                label:
                  "0" +
                  actionsRaw.adjustment_production_workers[i] +
                  " Workers",
                value: actionsRaw.adjustment_production_workers[i]
              })
            )
          }
        ]
      },
      distribution: {
        numItems:
          sales && inventory
            ? sales?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b) +
              inventory?.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b)
            : 0,
        data: [
          {
            time: "10:00",
            title: "First Distribution - Truck 1",
            data: actionsRaw.first_distribution_truck1.map(
              (d: number, i: number) => ({
                item: ITEMS[i].icon,
                label: d + " Items",
                value: d
              })
            )
          },
          {
            time: "10:00",
            title: "First Distribution - Truck 2",
            data: actionsRaw.first_distribution_truck2.map(
              (d: number, i: number) => ({
                item: ITEMS[i].icon,
                label: d + " Items",
                value: d
              })
            )
          },
          {
            time: "15:00",
            title: "Second Distribution - Truck 1",
            data: actionsRaw.second_distribution_truck1.map(
              (d: number, i: number) => ({
                item: ITEMS[i].icon,
                label: d + " Items",
                value: d
              })
            )
          },
          {
            time: "15:00",
            title: "Second Distribution - Truck 2",
            data: actionsRaw.second_distribution_truck2.map(
              (d: number, i: number) => ({
                item: ITEMS[i].icon,
                label: d + " Items",
                value: d
              })
            )
          }
        ]
      }
    };
  }

  const handleTabClick = (tabName: string) => {
    document.getElementById(tabName)?.scrollIntoView({ behavior: "smooth" });
  };

  const handleBackClick = useCallback(() => {
    setPage(EPages.FINANCIAL_OVERVIEW);
  }, []);

  const handleClick = useCallback(() => {
    setPage(EPages.FINAL_SCORE);
  }, []);

  const header = (
    <>
      <span className="flex">
        <TabRadio
          checked={selectedTab === content.productionLines}
          onChange={() => handleTabClick(content.productionLines)}
        >
          {content.productionLines}
        </TabRadio>
        <TabRadio
          checked={selectedTab === content.distribution}
          onChange={() => handleTabClick(content.distribution)}
        >
          {content.distribution}
        </TabRadio>
        <TabRadio
          checked={selectedTab === content.locations}
          onChange={() => handleTabClick(content.locations)}
        >
          {content.locations}
        </TabRadio>
      </span>
      <span />
    </>
  );

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.sidePanelTitle}
        subtitle={content.sidePanelSubtitle}
        textColor="text-white-light"
        className="bg-dark-sky-gradient"
      />
      <ContentLayout header={header} onBackClick={handleBackClick}>
        <ActionsTemplate data={actions} onTabChange={setSeletedTab} />
        <LowProgressBar
          stepComponents={EnumToArray(EPages)}
          text={content.ctaText}
          onClick={handleClick}
          page={EPages.ACTIONS}
        ></LowProgressBar>
      </ContentLayout>
    </Layout>
  );
};

export default ProductionOverviewPage;
