import React, { useState } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";

const StyledSliderContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-bottom;
  height: ${props => 230 * props.height}px;
`;

const StyledSlider = styled(ReactSlider)`
  width: 48px;
  height: 100%;
`;

const StyledTrack = styled.div<{ blocked: boolean }>`
  width: 16px;
  background-color: ${props => props.color};
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0 auto;
  opacity: ${props => (props.blocked ? 0.6 : 1)};
`;

const StyledThumb = styled.div`
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: white;
  outline: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  display: ${props => (props.hidden ? "none" : "flex")};
`;

const StyledValue = styled.b`
  color: ${props => props.color};
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
`;

const Track: React.FC<{
  props: any;
  state: any;
  color: string;
  blocked: boolean;
}> = ({ props, state, color, blocked }) => {
  const { index } = state;
  return (
    <StyledTrack
      {...props}
      color={index ? "#FFFFFF" : color}
      blocked={blocked}
    />
  );
};

interface ISliderProps {
  name: string;
  Image: any;
  onChange: (item: string, v: number) => void;
  maxValue?: number;
  allowedValue: number;
  maxOverallValue: number;
  color: string;
  darkColor: string;
}

const Slider: React.FC<ISliderProps> = ({
  name,
  Image,
  onChange,
  maxValue = 100,
  allowedValue,
  maxOverallValue,
  color,
  darkColor
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const height =
    maxOverallValue && maxValue < maxOverallValue
      ? 1 - (1 - maxValue / maxOverallValue) / 3
      : 1;

  const Thumb = (props, state) => (
    <StyledThumb {...props} hidden={state.index > 0}>
      <Image />
    </StyledThumb>
  );

  return (
    <StyledSliderContainer height={height}>
      <div
        style={{
          position: "relative",
          height: "100%",
          margin: "auto"
        }}
      >
        <StyledSlider
          min={0}
          max={maxValue}
          step={1}
          value={[currentValue, allowedValue]}
          orientation="vertical"
          invert
          onChange={values => {
            const value = Array.isArray(values) ? values[0] : values;
            if (value <= allowedValue) {
              setCurrentValue(value as number);
              onChange(name, value as number);
            }
          }}
          renderThumb={Thumb}
          renderTrack={(props, state) => (
            <Track
              key={state.index}
              props={props}
              state={state}
              color={color}
              blocked={state.index === 2}
            />
          )}
        />
      </div>
      <StyledValue color={darkColor}>{currentValue}</StyledValue>
    </StyledSliderContainer>
  );
};

export default Slider;
