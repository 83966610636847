import { atom } from "recoil";

export enum EPages {
  OPERATIONS_OVERVIEW = 0,
  PRODUCTION_SETUP = 1
}

const pageState = atom({
  key: "13-30am-state",
  default: EPages.PRODUCTION_SETUP
});

interface IPageData {
  lines: number[];
  workers: number[];
}
export const pageData = atom<IPageData>({
  key: "13-30-data",
  default: {
    lines: [],
    workers: []
  }
})
export default pageState;
