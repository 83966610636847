import React from "react";
import { useRecoilValue } from "recoil";
import { Switch, Route } from "@components/router";
import ProductionSetup from "./ProductionSetup";
import OperationsOverview from "./OperationsOverview";
import stateBusiness, { EPages } from "./state";

const Routes = () => {
  const page = useRecoilValue(stateBusiness);

  return (
    <Switch value={page}>
      <Route
        routeValue={EPages.OPERATIONS_OVERVIEW}
        Component={OperationsOverview}
      />
      <Route routeValue={EPages.PRODUCTION_SETUP} Component={ProductionSetup} />
    </Switch>
  );
};

export default Routes;
