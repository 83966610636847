import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Title3Light } from "@metyis-ds/typography";
import ContentLayout from "@components/layouts/ContentLayout";
import content from "./Operations.content";
import Layout from "@components/layouts/Layout";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";
import { XLButton } from "@metyis-ds/button";
import { authState } from "@state/auth";
import { ESteps, stepState } from "@state/step";
import OperationsTemplate from "@templates/operations/OperationsTemplate";
import api from "@api";

const BusinessOperations: React.FC = ({}) => {
  const auth = useRecoilValue(authState);
  const [step, setStep] = useRecoilState(stepState);
  const [isCtaLoading, setCtaIsLoading] = useState<boolean>(false);

  const handleCta = async () => {
    try {
      setCtaIsLoading(true);
      const { data, status } = await api.postStep({
        gameid: auth.gameid,
        userid: auth.userid,
        step: step + 1
      });
      if (status === 200) {
        setStep(data.step as ESteps);
      }
    } catch (e) {
      // handle error
    } finally {
      setCtaIsLoading(false);
    }
  };

  return (
    <Layout>
      <DefaultLeftPanel
        title={content.leftTitle}
        subtitle={content.leftSubtitle}
        className="bg-blue-gradient"
      />
      <ContentLayout hideHeader>
        <div className="pt-20 mb-md">
          <Title3Light>{content.title}</Title3Light>
          <OperationsTemplate />
        </div>
        <XLButton disabled={isCtaLoading} onClick={handleCta}>
          {content.cta}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default BusinessOperations;
