import { ESteps, stepState, TransitionParams } from "@state/step";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import BackgroundNight from "./background-night.png";
import BackgroundDay from "./background-day.png";
import BackgroundEndDay from "./background-sunset.png";
import { CSSTransition } from "react-transition-group";
import Moon from "../../assets/illustrations/checkpoints/moon.svg";
import Ground from "../../assets/illustrations/checkpoints/ground.svg";
import Cloud3 from "../../assets/illustrations/checkpoints/cloud3.svg";
import Cloud4 from "../../assets/illustrations/checkpoints/cloud4.svg";
import Stars from "../../assets/illustrations/checkpoints/stars.svg";
import content from "./Checkpoint.content";

import {
  StyledHill,
  StyledFactory,
  StyledCastle,
  StyledCity,
  StyledLighthouse,
  StyledFarm,
  StyledMountain,
  StyledFactoryLine,
  StyledTruck,
  StyledCloudContainer,
  StyledSea,
  StyledTruck2,
  StyledSky,
  StyledSky2,
  StyledSky2Inactive,
  StyledSky3,
  StyledSky3Inactive,
  StyledSky4,
  StyledSky4Inactive,
  StyledSky5,
  StyledSky5Inactive,
  StyledSky6,
  StyledSky6Inactive,
  StyledSky7,
  StyledSky7Inactive,
  StyledSky8,
  StyledSky9,
  StyledSky8Inactive,
  StyledHillInactive,
  StyledCastleInactive,
  StyledFactoryInactive,
  StyledLighthouseInactive,
  StyledCityInactive,
  StyledFarmInactive,
  StyledMountainInactive
} from "../../components/illustrations/Illustrations";

import styled, { css } from "styled-components";
import { Body2Bold, H1Bold, Body2Light, Text2Bold } from "@metyis-ds/typography";
import ProgressBar from "@components/progress-bar/ProgressBar";

import { Flipper, Flipped } from "react-flip-toolkit";
import api from "@api";
import { authState } from "@state/auth";

const CheckpointTitle = styled(H1Bold)(
  ({ theme }) => css`
    color: ${theme.colors.white.light};
    opacity: 0;
    animation: fade-in 0.5s forwards;
    .&exiting {
    }
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
);

/* Components */

const getComponent = (
  step: number,
  after?: boolean,
  overrideTitle?: string
) => {
  switch (step) {
    case ESteps.CHECKPOINT_1:
      return (
        <>
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledSea />
            <StyledCity />
            <StyledFarm />
            <StyledMountain />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
          </div>
          <Stars />
          <Flipped flipId="moon">
            <Moon className="absolute right-20 top-20" />
          </Flipped>
          <div className="absolute top-0 left-0 min-h-screen w-screen flex justify-center items-center">
            <CheckpointTitle>
              {overrideTitle ? overrideTitle : content.firstProductionRound}
            </CheckpointTitle>
          </div>
        </>
      );
    case ESteps.CHECKPOINT_2:
      return (
        <>
          {after ? <StyledSky2Inactive /> : <StyledSky />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHillInactive />
            <StyledCastleInactive />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouseInactive />
            <StyledMountain />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
            <Flipped flipId="truck">
              <StyledTruck />
            </Flipped>
            <StyledFactoryLine />
            <StyledTruck2 />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-30 right-30" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-40" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.firstBatchIsOut}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_3:
      return (
        <>
          {after ? <StyledSky3Inactive /> : <StyledSky2 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <StyledFactoryInactive />
            <StyledTruck gone />
            <StyledTruck2 gone />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-30 right-40" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-50" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.timeToDistribute}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_4:
      return (
        <>
          {after ? <StyledSky4Inactive /> : <StyledSky3 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHillInactive />
            <StyledCastleInactive />
            <StyledCityInactive />
            <StyledFarmInactive />
            <StyledLighthouseInactive />
            <StyledMountainInactive />
            <StyledFactory />
            {/* <Flipped flipId="truck">
              <StyledTruck />
            </Flipped>
            <StyledTruck2 /> */}
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-30 right-40" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-40" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.secondProductionRound}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_5:
      return (
        <>
          {after ? <StyledSky5Inactive /> : <StyledSky4 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <StyledFactory />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-30 right-35" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-25 left-30" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.preliminarySalesResults}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_6:
      return (
        <>
          {after ? <StyledSky6Inactive /> : <StyledSky5 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
            <Flipped flipId="truck">
              <StyledTruck />
            </Flipped>
            <StyledTruck2 />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-30" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-30" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.oportunityAdjustments}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_7:
      return (
        <>
          {after ? <StyledSky7Inactive /> : <StyledSky6 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHillInactive />
            <StyledCastleInactive />
            <StyledCityInactive />
            <StyledFarmInactive />
            <StyledLighthouseInactive />
            <StyledMountainInactive />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
            <Flipped flipId="truck">
              <StyledTruck />
            </Flipped>
            <StyledFactoryLine />
            <StyledTruck2 />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-30" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-30" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.secondBatchOut}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_8:
      return (
        <>
          {after ? <StyledSky7Inactive /> : <StyledSky7Inactive />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <StyledFactoryInactive />
            <Flipped flipId="truck">
              <StyledTruck gone />
            </Flipped>
            <StyledTruck2 gone />
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-30" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-30" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.timeToDistribute}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_9:
      return (
        <>
          {after ? <StyledSky8Inactive /> : <StyledSky8 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-30" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-30" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.AllDayResults}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_10:
      return (
        <>
          {after ? <StyledSky8Inactive /> : <StyledSky8Inactive />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHill />
            <StyledCastle />
            <StyledCity />
            <StyledFarm />
            <StyledLighthouse />
            <StyledMountain />
            <Flipped flipId="factory">
              <StyledFactory />
            </Flipped>
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-60" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 left-70" />
            </Flipped>
          </StyledCloudContainer>
          <CheckpointTitle className="text-blue-dark">
            {overrideTitle ? overrideTitle : content.finalOportunity}
          </CheckpointTitle>
        </>
      );
    case ESteps.CHECKPOINT_11:
      return (
        <>
          {after ? <StyledSky8Inactive /> : <StyledSky9 />}
          <Ground
            className="absolute bottom-0 left-0 w-screen"
            style={{ height: "100px" }}
          />
          <div className="absolute w-screen bottom-0 left-0 flex justify-start mb-xl">
            <StyledHillInactive />
            <StyledCastleInactive />
            <StyledCityInactive />
            <StyledFarmInactive />
            <StyledLighthouseInactive />
            <StyledMountainInactive />
            <Flipped flipId="factory">
              <StyledFactoryInactive />
            </Flipped>
          </div>
          <StyledCloudContainer>
            <Flipped flipId="cloud">
              <Cloud3 className="absolute top-25 right-60" />
            </Flipped>
            <Flipped flipId="cloud2">
              <Cloud4 className="absolute top-40 right-70" />
            </Flipped>
          </StyledCloudContainer>
          <Stars />
          <Flipped flipId="moon">
            <Moon className="absolute left-20" style={{ top: 200 }} />
          </Flipped>
          <div className="absolute top-0 left-0 min-h-screen w-screen flex justify-center items-center">
            <CheckpointTitle>
              {overrideTitle ? overrideTitle : content.endOfDay}
            </CheckpointTitle>
          </div>
        </>
      );
  }
};
const getCheckpointBackground = (step: number, after?: boolean) => {
  switch (step) {
    case ESteps.CHECKPOINT_1:
      return BackgroundNight;
    case ESteps.CHECKPOINT_2:
      return after ? BackgroundDay : BackgroundNight;
    case ESteps.CHECKPOINT_3:
      return BackgroundDay;
    case ESteps.CHECKPOINT_4:
      return BackgroundDay;
    case ESteps.CHECKPOINT_5:
      return BackgroundDay;
    case ESteps.CHECKPOINT_6:
      return BackgroundDay;
    case ESteps.CHECKPOINT_7:
      return BackgroundDay;
    case ESteps.CHECKPOINT_8:
      return BackgroundDay;
    case ESteps.CHECKPOINT_9:
      return after ? BackgroundEndDay : BackgroundDay;
    case ESteps.CHECKPOINT_10:
      return BackgroundEndDay;
    case ESteps.CHECKPOINT_11:
      return after ? BackgroundNight : BackgroundEndDay;
    /* TODO add more checkpoints */
  }
};
const getComponentsOfCheckpoint = (step: number, after?: boolean) => {
  if (after) {
    switch (step) {
      case ESteps.CHECKPOINT_1:
        return getComponent(ESteps.CHECKPOINT_1);
      case ESteps.CHECKPOINT_2:
        return getComponent(ESteps.CHECKPOINT_2);
      case ESteps.CHECKPOINT_3:
        return getComponent(ESteps.CHECKPOINT_3);
      case ESteps.CHECKPOINT_4:
        return getComponent(ESteps.CHECKPOINT_4);
      case ESteps.CHECKPOINT_5:
        return getComponent(ESteps.CHECKPOINT_5);
      case ESteps.CHECKPOINT_6:
        return getComponent(ESteps.CHECKPOINT_6);
      case ESteps.CHECKPOINT_7:
        return getComponent(ESteps.CHECKPOINT_7);

      case ESteps.CHECKPOINT_8:
        return getComponent(ESteps.CHECKPOINT_8);

      case ESteps.CHECKPOINT_9:
        return getComponent(ESteps.CHECKPOINT_9);

      case ESteps.CHECKPOINT_10:
        return getComponent(ESteps.CHECKPOINT_10);

      case ESteps.CHECKPOINT_11:
        return getComponent(ESteps.CHECKPOINT_11);

      /* TODO add more checkpoints */
    }
  } else {
    switch (step) {
      case ESteps.CHECKPOINT_1:
        return getComponent(ESteps.CHECKPOINT_1);
      case ESteps.CHECKPOINT_2:
        return getComponent(
          ESteps.CHECKPOINT_1,
          false,
          content.firstBatchIsOut
        );
      case ESteps.CHECKPOINT_3:
        return getComponent(
          ESteps.CHECKPOINT_2,
          true,
          content.timeToDistribute
        );
      case ESteps.CHECKPOINT_4:
        return getComponent(
          ESteps.CHECKPOINT_3,
          true,
          content.secondProductionRound
        );
      case ESteps.CHECKPOINT_5:
        return getComponent(
          ESteps.CHECKPOINT_4,
          true,
          content.preliminarySalesResults
        );
      case ESteps.CHECKPOINT_6:
        return getComponent(
          ESteps.CHECKPOINT_5,
          true,
          content.oportunityAdjustments
        );
      case ESteps.CHECKPOINT_7:
        return getComponent(ESteps.CHECKPOINT_6, true, content.secondBatchOut);
      case ESteps.CHECKPOINT_8:
        return getComponent(
          ESteps.CHECKPOINT_7,
          true,
          content.timeToDistribute
        );
      case ESteps.CHECKPOINT_9:
        return getComponent(ESteps.CHECKPOINT_8, true, content.AllDayResults);
      case ESteps.CHECKPOINT_10:
        return getComponent(ESteps.CHECKPOINT_9, true, content.finalOportunity);
      case ESteps.CHECKPOINT_11:
        return getComponent(ESteps.CHECKPOINT_10, true, content.endOfDay);
      /* TODO add more checkpoints */
    }
  }
};

let hasChecked: boolean = false;
let intervalId: any;
const CheckpointBackground = styled.div<{ background: string }>(
  ({ background }) => css`
    background-image: url(${background});
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-image 2s;
  `
);

/* Logic for Checkpoints is a 4 second interval that requests the be if the user can move. Request
returns the number of players waiting and the total players in the game. If the be allows the game to follow,
we have a transition to the next screen (controled by the variable after), and after a while the game will jump a step to the next page */

const Checkpoint = () => {
  const [after, setIsAfter] = useState(false);
  const auth = useRecoilValue(authState);
  const [step, setStep] = useRecoilState(stepState);
  // let step = ESteps.CHECKPOINT_3

  const [numPlayersConfirmed, setNumPlayersConfirmed] = useState<
    number | undefined
  >(0);
  const [numPlayersTotal, setNumPlayersTotal] = useState<number | undefined>(0);
  const [canMove, setCanMove] = useState(false);
  const [shuffle, setShuffle] = useState(false);

  const handleNextStep = async () => {
    const { status } = await api.postStep({
      gameid: auth.gameid,
      userid: auth.userid,
      step: step + 1
    });
    if (status === 200) {
      setStep(step + 1);
    }
  };
  const checkGameMove = async () => {
    if (auth.gameid && auth.userid && step != undefined) {
      const { data, status } = await api.postCanIMove({
        gameid: auth.gameid,
        userid: auth.userid,
        step
      });
      if (status === 200) {
        setNumPlayersConfirmed(data.numPlayersReady);
        setNumPlayersTotal(data.numPlayersTotal);
        if (data.move) {
          setCanMove(true);
        }
      }
    }
  };

  useEffect(() => {
    let timeout: any;
    if (canMove && hasChecked) {
      // timeout = setTimeout(() => {
      //   handleNextStep();
      // }, 4000);
      clearInterval(intervalId);
    }
    if (!after && canMove && hasChecked) {
      setTimeout(() => {
        setIsAfter(true);
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [canMove, hasChecked]);

  useEffect(() => {
    hasChecked = true;
    // checkGameMove();
    intervalId = setInterval(() => {
      checkGameMove();
      setShuffle(shuffle => !shuffle);
    }, 2000);
    return () => {
      hasChecked = false;
      clearInterval(intervalId);
    };
  }, []);

  const remainingPlayers = (numPlayersTotal || 0) - (numPlayersConfirmed || 0);
  return (
    <>
      <CheckpointBackground
        className="relative h-screen w-screen"
        background={getCheckpointBackground(step, after)}
      >
        <Flipper flipKey={after} spring="veryGentle">
          {getComponentsOfCheckpoint(step, after)}
        </Flipper>
      </CheckpointBackground>
      <Flipper flipKey={after} spring="veryGentle">
        <ProgressBar
          progress={
            after
              ? TransitionParams[step].progress
              : TransitionParams[step].progressBefore
          }
          inverted={
            after
              ? TransitionParams[step].inverted
              : TransitionParams[step].invertedBefore
          }
          text={
            after
              ? TransitionParams[step].text
              : TransitionParams[step].textBefore
          }
        />
      </Flipper>
      <div className="absolute top-xxl flex w-screen justify-center items-center">
        <Body2Bold
          className={
            TransitionParams[step].inverted
              ? "text-white-light"
              : "text-blue-dark"
          }
        >
          {content.title}
        </Body2Bold>
      </div>
      <div
        className="absolute flex w-screen justify-center items-center"
        style={{ top: "180px" }}
      >
        {/* TODO maybe change this style */}
        {!canMove && numPlayersTotal != 0 && (
          <CSSTransition
            timeout={300}
            in={shuffle}
            classNames="waiting-warning"
          >
            {shuffle ? (
              <Body2Light className="text-red-medium">
                {content.moveTogether}
              </Body2Light>
            ) : (
              <Body2Light className="text-red-medium">
                {content.waitFor} {remainingPlayers}{" "}
                {remainingPlayers == 1 ? content.colleague : content.colleagues}
              </Body2Light>
            )}
          </CSSTransition>
        )}
        {canMove && <div className="mt-xl bg-green-medium text-blue-dark px-md py-sm rounded-md cursor-pointer hover:bg-green-dark" onClick={() => handleNextStep()}>
              <Text2Bold>Continue</Text2Bold>
        </div>}
      </div>
    </>
  );
};

export default Checkpoint;
