import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Layout from "@components/layouts/Layout";

interface ISidePanelProps {}

const SidePanel: React.FC<ISidePanelProps> = ({ children }) => {
  const [el] = useState(document.createElement("div"));

  const modalEl = document.getElementById("modal-root");

  useEffect(() => {
    modalEl?.appendChild(el);

    return () => {
      modalEl?.removeChild(el);
    };
  }, []);

  const child = (
    <Layout className="absolute inset-0 h-screen w-screen z-modal">
      <div className="bg-grey-medium opacity-70" />
      <div className="bg-white-light">{children}</div>
    </Layout>
  );

  return createPortal(child, el);
};

export default SidePanel;
