import React from "react";
import { useDrag } from "react-dnd";
import styled, { css } from "styled-components";

interface ItemProps {
  name: string;
  Image: any;
  numItems?: number;
  isDropped: (e: string, bin: string) => void;
  onClick?: () => void;
}
interface DropResult {
  name: string;
}

const StyledItem = styled.div<{ isDragging?: boolean }>(
  ({ isDragging }) => css`
    border: ${isDragging ? "1px dashed #4E5559" : "1px solid transparent"};
    border-radius: 10px;
    background-color: white;
    margin-bottom: 1.5rem;
    cursor: move;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${isDragging ? 0.4 : 1};
    width: 48px;
    height: 48px;
    box-shadow: ${isDragging
      ? "none"
      : "0px 4px 40px -10px rgba(0, 0, 0, 0.4)"};
  `
);

const StyledNumItems = styled.p`
  color: #4e5559;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const Item: React.FC<ItemProps> = ({
  name,
  numItems = 100,
  isDropped,
  Image,
  onClick
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "CARD",
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        isDropped(item.name, dropResult.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div onClick={onClick}>
      <StyledNumItems>{numItems}</StyledNumItems>
      <StyledItem
        isDragging={isDragging}
        ref={drag}
        role="Box"
        data-testid={`box-${name}`}
      >
        <Image width={35} height={35} />
      </StyledItem>
    </div>
  );
};

export default Item;
