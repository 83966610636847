import React, { useEffect, useState } from "react";
import ImageModalInside from "@templates/image-modal/ImageModal";

interface IImageModalProps {
    src: string,
    name: string;
    grans: string[];
    slideIndex: number;
    showModal: boolean;
    setShowModal: (s: boolean) => void;
}

const ImageModal: React.FC<IImageModalProps> = ({ src, slideIndex, grans, name, showModal, setShowModal }) => {

  useEffect(() => {
    window.onbeforeunload = async e => {
      setShowModal(true);
      return;
    };
  }, []);

  const onNo = () => {};

  const onYes = () => {};

  return (
    <div>
      <ImageModalInside
        slideIndex={slideIndex}
        grans={grans}
        name={name}
        show={showModal}
        src={src}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default ImageModal;
