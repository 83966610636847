import React, { useCallback, useEffect, useRef, useState } from "react";
import { Title2Bold } from "@metyis-ds/typography";
import ActionsSummary, { IActionsSummary } from "./ActionsSummary";
import content from "./actions.content";

interface IProps {
  data: {
    [key: string]: IActionsSummary;
  };
  onTabChange: (tab: string) => void;
}

const ActionsTemplate: React.FC<IProps> = ({ data, onTabChange }) => {
  const [observer, setObserver] = useState<IntersectionObserver>();
  const summary1Ref = useRef<HTMLDivElement>(null);
  const summary2Ref = useRef<HTMLDivElement>(null);
  const summary3Ref = useRef<HTMLDivElement>(null);

  const onVisible = useCallback(entries => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        onTabChange(entry.target.id);
      }
    });
  }, []);

  // setup observer
  useEffect(() => {
    const observerOptions = { threshold: 0.9 };
    const newObserver = new IntersectionObserver(onVisible, observerOptions);

    setObserver(newObserver);

    return () => {
      newObserver.disconnect();
    };
  }, [onVisible]);

  // use observer to look out for changes in ref
  useEffect(() => {
    if (
      observer &&
      summary1Ref.current &&
      summary2Ref.current &&
      summary3Ref.current
    ) {
      observer.observe(summary1Ref.current);
      observer.observe(summary2Ref.current);
      observer.observe(summary3Ref.current);

      return () => {
        if (summary1Ref.current && summary2Ref.current && summary3Ref.current) {
          observer.unobserve(summary1Ref.current);
          observer.unobserve(summary2Ref.current);
          observer.unobserve(summary3Ref.current);
        }
      };
    }
  }, [observer, summary1Ref.current, summary2Ref.current, summary3Ref.current]);
  return (
    <div className="flex flex-col mb-xxxl">
      <Title2Bold className="mb-md">{content.title}</Title2Bold>
      <div className="flex flex-col overflow-auto">
        <ActionsSummary
          className="mb-xl"
          id={content.productionLines}
          ref={summary1Ref}
          type="production"
          {...data["production"]}
        />
        <ActionsSummary
          className="mb-xl"
          id={content.distribution}
          ref={summary2Ref}
          type="distribution"
          {...data["distribution"]}
        />
        <ActionsSummary
          id={content.locations}
          ref={summary3Ref}
          type="location"
          {...data["location"]}
        />
      </div>
    </div>
  );
};

export default ActionsTemplate;
