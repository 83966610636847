import React, { useEffect, useState } from "react";
import * as confetti from "canvas-confetti";
import { SButton, XLButton } from "@metyis-ds/button";
import {
  H1Bold,
  Body2Bold,
  Body2Light,
  Text2Bold,
  Text1Bold,
  Title2Bold,
  Title2Light,
  Body1Bold,
  Text1Light
} from "@metyis-ds/typography";
import { Donut } from "@metyis-ds/donut";
import { defaultTheme } from "@metyis-ds/tokens";
import { Icon } from "@metyis-ds/icon";
import Layout from "@components/layouts/Layout";
import ContentLayout from "@components/layouts/ContentLayout";
import content from "@routes/final-score/FinalScore.content";
import Medal1 from "@assets/icons/medal1.svg";
import Medal2 from "@assets/icons/medal2.svg";
import Medal3 from "@assets/icons/medal3.svg";
import Star from "@assets/icons/star.svg";
import "@routes/final-score/FinalScore.css";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import pageState, { EPages, pageData } from "./state";
import { authState } from "@state/auth";
import WinnerLeftPanel from "@components/left-panel/WinnerLeftPanel";
import { LowProgressBar } from "@components/low-progress-bar/ProgressBar";
import { EnumToArray } from "../../constants";
import api from "@api";
import { ESteps, stepState } from "@state/step";
import DefaultLeftPanel from "@components/left-panel/DefaultLeftPanel";

const FinalScore = () => {
  const opacityRange = ["opacity-90", "opacity-70", "opacity-40", "opacity-20"];
  const rawScores = useRecoilValue(pageData).scores;
  const [auth, setAuth] = useRecoilState(authState);
  const setStep = useSetRecoilState(stepState);
  const [position, setPosition] = useState(null);

  const getNumberWithOrdinal = (number: number) => {
    const s = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const players = rawScores.players.map((d: string, i: number) => {
    return {
      name: d,
      score: rawScores.scores
        ? rawScores.scores[i]
            .reduce((a: any, b: any) => a.concat(b))
            .reduce((a: number, b: number) => a + b)
        : 0
    };
  });

  const maxScore = 1000;

  const resetGame = async () => {
    const { status } = await api.postResetGame({ gameid: auth.gameid });
    if (status == 200) {
      setAuth({ gameid: "", userid: "" });
      setStep(ESteps.LOGIN);
    }
  };

  const getMedal = (position: number) => {
    switch (position) {
      case 0:
        return <Medal1 />;
      case 1:
        return <Medal2 />;
      case 2:
        return <Medal3 />;
      default:
        return <></>;
    }
  };

  const renderHeaderElements = (position: number) => {
    return (
      <>
        <span
          className="flex items-center cursor-pointer"
          onClick={() => setPage(EPages.FINAL_SCORE - 1)}
        >
          <Icon name="arrow-left" height={24} width={24} />
          <Text1Light className="ml-xs">{content.goBack}</Text1Light>
        </span>
        <div className="div__medal">
          {getMedal(position)}
          <Text2Bold className="text-red-medium">
            {getNumberWithOrdinal(position + 1)} {content.scorePlace}
          </Text2Bold>
          <div className="div__triangle"></div>
        </div>
      </>
    );
  };

  const renderCanvasConfettiMiddle = () => (
    <canvas
      className="absolute flex flex-col items-center top-16"
      id="confetti-canvas"
      height="600"
      width="800"
    />
  );

  const renderDonut = (profit: number) => (
    <Donut
      values={[
        {
          id: "1",
          label: "",
          usage: (profit / maxScore) * 100,
          donutColor: "#FFC907"
        }
      ]}
      defaultDonutColor={defaultTheme.colors.white.dark}
      donutThickness={18}
      width={179}
      height={179}
    >
      <div className="flex flex-col items-center mt-12 text-yellow-medium">
        <Body1Bold>{profit}</Body1Bold>
        <Text1Bold>{content.points}</Text1Bold>
      </div>
    </Donut>
  );

  const renderPerformance = (score: number) => {
    if (score > 900) {
      return content.scoreType.excellent;
    } else if (score > 300) {
      return content.scoreType.good;
    } else {
      return content.scoreType.reasonable;
    }
  };
  const renderStars = (score: number) => {
    if (score > 900) {
      return [1, 2, 3, 4, 5];
    } else if (score > 300) {
      return [1, 2, 3, 4];
    } else if (score > 100) {
      return [1, 2, 3];
    } else {
      return [1, 2];
    }
  };

  const renderUserInfo = (
    name: string,
    profit: number,
    range: string,
    position: number
  ) => {
    return (
      <div
        className={`div__users bg-${range} border-solid rounded-2xl w-11/12 ${
          name == auth.userid && "border-4 border-yellow-medium"
        }`}
      >
        {position == 0 ? (
          <Medal1 className="h-6 m-2" />
        ) : position == 1 ? (
          <Medal2 className="h-6 m-2" />
        ) : position == 2 ? (
          <Medal3 className="h-6 m-2" />
        ) : (
          <div className="h-6 m-2" style={{ minWidth: "15px" }} />
        )}
        <div className="flex items-center w-full">
          <Text1Bold>{getNumberWithOrdinal(position + 1)}</Text1Bold>
          <Text1Bold className="flex-grow mx-sm text-left">{name}</Text1Bold>
          <Text1Bold className="mx-4">
            {profit} {content.points}
          </Text1Bold>
        </div>
      </div>
    );
  };

  const setPage = useSetRecoilState(pageState);
  const myScore = players.find((d: any) => d.name == auth.userid).score || 0.0;

  useEffect(() => {
    const myPosition = players.findIndex((d: any) => d.name == auth.userid);
    setPosition(myPosition);
  }, [players]);

  console.log(
    "MY POSITION ",
    auth.userid,
    position,
    players,
    renderStars(myScore)
  );

  return (
    <Layout>
      {position == 0 ? (
        <WinnerLeftPanel
          title={content.congratsTitle}
          subtitle={content.finalScoreTitle}
          textColor="text-white-light"
          className="bg-dark-sky-gradient"
        />
      ) : (
        <DefaultLeftPanel
          title={content.congratsTitle}
          subtitle={content.finalScoreTitle}
          textColor="text-white-light"
          className="bg-dark-sky-gradient"
        />
      )}
      {position != null && (
        <ContentLayout header={renderHeaderElements(position)}>
          <div className="h-full overflow-auto pr-15 text-center pr-20 pb-10">
            <H1Bold className="mb-2">{content.scoreTitle}</H1Bold>
            <Body2Light>{content.youNowHave}</Body2Light>
            {/* {renderCanvasConfettiMiddle()} */}
            <div className="mt-1 flex flex-col items-center">
              <Body2Bold className="text-yellow-medium mb-2">
                {renderPerformance(myScore)} Performance
              </Body2Bold>
              <div className="mb-3 flex animate-ping">
                {renderStars(myScore).map(d => (
                  <Star />
                ))}
              </div>
            </div>
            <div
              className="w-40 h-44 my-0 mx-auto"
              style={{ minHeight: "300px" }}
            >
              {renderDonut(
                players.find((d: any) => d.name == auth.userid).score
              )}
            </div>
            <div className="h-48 flex flex-col items-center justify-center mb-xl">
              <Body2Light className="mt-16 mb-4">
                {content.finalScore}
              </Body2Light>
              {players
                .sort((a: any, b: any) => (a.score - b.score > 0 ? -1 : 1))
                .map((d: any, i: number) => {
                  return renderUserInfo(d.name, d.score, "1", i);
                })}
            </div>
          </div>
          <LowProgressBar
            stepComponents={EnumToArray(EPages)}
            text={content.startGameBtn}
            onClick={resetGame}
            page={EPages.FINAL_SCORE}
          ></LowProgressBar>
        </ContentLayout>
      )}
    </Layout>
  );
};

export default FinalScore;
