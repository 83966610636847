import React from "react";
import styled, { css, useTheme } from "styled-components";
// import { Title2Light, Text4Bold, Text, Text4Light } from "../typography/Typography";
import classnames from "classnames";
import { Text4Bold, Text4Light, Title2Light } from "@metyis-ds/typography";
import { LButton } from "@metyis-ds/button";
import { useRecoilValue } from "recoil";
import { stepState } from "@state/step";

import { EPages } from "@routes/step-9-30";
// import { useDispatch, useSelector } from "react-redux";
// import { IAppState } from "../../store";
// import { resetGame, setStep } from "../../store/logging/actions";
// import Icon from "../icon/Icon";

const stepDefinitions = [{ step: 5, components: [] }];

const stepsBefore5 = [
  { name: "Location", step: 2 },
  { name: "Inventory", step: 3 },
  { name: "Financial Overview", step: 4 }
];
const stepsBefore8 = [
  { name: "Financial Overview", step: 5 },
  { name: "Location", step: 6 },
  { name: "Marketing Campaign", step: 7 }
];
const stepsBefore12 = [
  { name: "Financial Overview", step: 8 },
  { name: "Inventory Overview", step: 9 },
  { name: "Location", step: 10 },
  { name: "Price Markdown", step: 11 }
];
const stepsBefore16 = [
  { name: "Inventory Overview", step: 12 },
  { name: "Financial Overview", step: 13 },
  { name: "My Actions", step: 14 }
];

const stepsBeforeEnd = [
  { name: "Inventory Overview", page: 0 },
  { name: "Financial Overview", page: 1 },
  { name: "My Actions", page: 2 },
  { name: "My Score", page: 3 }
];
const steps = [
  { name: "Your business", step: 2 },

  { name: "Location", step: 3 },
  { name: "Inventory Overview", step: 4 },
  { name: "Financial Overview", step: 5 },
  { name: "Price Markdown", step: 6 }
];
export interface IProgressBarProps {
  text: string;
  disabled?: boolean;
  opBeforeSteping?: () => void;
  dontIncreaseStep?: boolean;
  endGame?: boolean;
  page: number;
  stepComponents: string[];
  onClick: () => void;
}

const StyledTitle2 = styled(Title2Light)(
  ({ theme }) => css`
    margin-left: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);
export const LowProgressBar: React.FC<IProgressBarProps> = ({
  text,
  disabled,
  opBeforeSteping,
  dontIncreaseStep,
  endGame,
  onClick,
  page,
  stepComponents
}) => {
  const step = useRecoilValue(stepState);
  return (
    <div className="progress-bar-container">
      <LButton disabled={disabled} onClick={onClick}>
        {text}
      </LButton>
      <ProgressBarBottom>
        <div className="progress-steps">
          <div className="progress-dummy">
            <div className="border-div full" />
          </div>
          {stepComponents.map((stepObject, index) => {
            return (
              <div key={index}>
                <div
                  className={classnames("border-div", {
                    full: index < page,
                    half: page == index
                  })}
                ></div>
                {page == index ? (
                  <Text4Bold key={stepObject}>{stepObject}</Text4Bold>
                ) : (
                  <Text4Light key={stepObject}>{stepObject}</Text4Light>
                )}
              </div>
            );
          })}
        </div>
        <div className="day-subtitle">
          <Text4Bold>{getPartOfDay(step || 0)}</Text4Bold>
        </div>
      </ProgressBarBottom>
    </div>
  );
};

const getPartOfDay = (step: number) => {
  if (step <= 5) {
    return "9:30am";
  } else if (step < 12) {
    return "12:30pm";
  } else if (step < 20) {
    return "6:00pm";
  } else {
    return "7:00pm";
  }
};

const ProgressBarLine = styled.div<{ progress: number }>(
  ({ theme, progress }) => css`
    height: 5px;
    background-color: ${theme.colors.white};
    position: relative;
    .progress-line-inside {
      position: absolute;
      top: 0px;
      height: 5px;
      border-right: 5px solid ${theme.colors.red.medium};
      left: 0px;
      width: ${progress}%;
      background-color: ${theme.colors.red.medium};
    }
  `
);

const ProgressBarContainer = styled.div<{ disabled?: boolean }>(
  ({ theme, disabled }) => css`
    max-height: 108px;
    background-color: ${disabled
      ? theme.colors.grey.medium
      : theme.colors.blue.medium};
    color: ${disabled ? theme.colors.grey.dark : theme.colors.yellow.medium};
    cursor: ${disabled ? "normal" : "pointer"};
    display: flex;
    justy-content: center;
    align-items: center;
    padding: 24px 64px;

    @media (max-width: 1300px) {
      padding: 24px 32px;
    }
    .icon {
      stroke: ${disabled ? theme.colors.grey.dark : theme.colors.yellow.light};
    }
  `
);
const ProgressBarBottom = styled.div(
  ({ theme }) => css`
    height: 42px;
    display: flex;
    background-color: ${theme.colors.white};
    justify-content: space-between;
    .day-subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 64px;
      @media (max-width: 1300px) {
        padding-right: 32px;
      }
    }
    .progress-steps {
      display: flex;
      > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &.progress-dummy {
          min-width: 48px;
          @media (max-width: 1300px) {
            min-width: 26px;
          }
        }
        .border-div {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 5px;
          width: 0%;
          background-color: ${theme.colors.red.medium};
          &.full {
            width: 100%;
          }
          &.half {
            width: 0%;
            border-right: 5px solid ${theme.colors.red.dark};
            animation: slide-width 0.5s forwards;
          }
        }
      }
      span {
        margin: 0px 16px;
      }
    }

    @keyframes slide-width {
      from {
        width: 0%;
      }
      to {
        width: 50%;
      }
    }
  `
);

const StyledProgressBar = styled(LowProgressBar)(
  ({ theme }) => css`
    border: 1px solid;
    border-color: ${theme.colors.blue};
    font-size: 16px;
    padding: 7px 16px;
    border-radius: 17px;
    background-color: transparent;
    &:hover {
      color: ${theme.colors.white};
      background-color: ${theme.colors.blue};
    }
  `
);

export default StyledProgressBar;
