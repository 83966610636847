export default {
  firstLineTitle: "3 production lines",
  firstLineSubtitle: "Max. 5 workers per line",
  secondLineTitle: "10 workers",
  secondLineSubtitle: "Capable of producing any item",
  thirdLineTitle: "4 food items",
  thirdLineSubtitle: "Max. 1 food item per line",
  fourthLineTitle: "2 trucks",
  fourthLineSubtitle: "Max. 70 items per truck",
  fifthLineTitle: "6 markets",
  fifthLineSubtitle: "Max. 1 destination per truck"
};
